import * as Actions from './action';
import { ActionReturns } from '../../util/tshelpers';
import { CCSTORE } from './constants';
import { CCState, PriceViewType } from './definitions';

type Actions = ActionReturns<typeof Actions>;

const defaultPriceViewTypes = [
  { id: ',price,regular,0000060', name: 'Hour', defForced: true },
  { id: ',price,regular,0001440', name: 'Day', defForced: false },
  //{ id: ',price,regular,0001600', name: 'Day' },
] as PriceViewType[];

const initialState = {
  priceSeries: defaultPriceViewTypes,
} as CCState;

const pluralize = (d: string, a: number) => {
  if (a == 0) return '';
  if (a > 1) return `${a} ${d}s`;
  else return `${a} ${d}`;
};

const minutesToDescription = (mins: number) => {
  switch (mins) {
    case 1:
      return 'Minute';
    case 60:
      return 'Hour';
    case 24 * 60:
      return 'Day';
    case 7 * 24 * 60:
      return 'Week';
    default:
      break;
  }
  let weeks = 0,
    days = 0,
    hours = 0;
  if (mins > 14 * 24 * 60)
    // only do this for > 2 weeks
    while (mins > 7 * 24 * 60) {
      mins -= 7 * 24 * 60;
      weeks++;
    }
  while (mins > 24 * 60) {
    mins -= 24 * 60;
    days++;
  }
  while (mins > 60) {
    mins -= 60;
    hours++;
  }
  return (
    (
      `${pluralize('Week', weeks)} ${pluralize('Days', days)} ${pluralize(
        'Hour',
        hours
      )} ${pluralize('Minute', mins)}` as any
    ).replaceAll(/\s{2,100}/g, ' ') as string
  ).trim();
};

const idToDescription = (id: string) =>
  minutesToDescription(parseInt(id.split(',').splice(-1)[0]));

export function reducer(state = initialState, action: Actions): CCState {
  switch (action.type) {
    case CCSTORE.UPDATE_CALC_CACHE: {
      // rebuild our priceViewTypes before storing to the cache.
      let priceViewTypes =
        action.entry.data?.zones?.reduce((acc, cur) => {
          for (let series of cur?.series) {
            //series = { ...series, outID: ',price,regular,0001600' };
            if (
              series.category !== 'regular' ||
              series.valueKind !== 'price' ||
              !series.outID.startsWith(',price,regular')
            )
              continue;
            if (!acc.find((pvt) => pvt.id === series.outID))
              acc = [
                ...acc,
                {
                  id: series.outID,
                  name: idToDescription(series.outID),
                  defForced: false,
                },
              ];
          }
          return acc;
        }, defaultPriceViewTypes) ?? state.priceSeries;

      console.log(action.entry, priceViewTypes);
      return { ...state, cached: action.entry, priceSeries: priceViewTypes };
    }
    case CCSTORE.UPDATE_COMP_CALC_CACHE: {
      return { ...state, compareCached: action.entry };
    }
    case CCSTORE.UPDATE_BOOKING_CACHE: {
      var newState = {
        ...state,
        cached: {
          ...state.cached!,
          data: {
            ...state.cached?.data,
            softErrors: state?.cached?.data?.softErrors ?? [],
            zones: state!.cached!.data.zones.map((zone) => {
              if (zone.zoneId === action.zoneId.toString()) {
                return {
                  ...zone,
                  series: zone.series.map((series) => {
                    if (series.outID === 'capacity_booking') {
                      series.values = action.booking;
                    }
                    if (series.outID === 'capacity_driveup') {
                      series.values = action.driveup;
                    }
                    return series;
                  }),
                };
              }
              return zone;
            }),
          },
        },
      };
      return newState;
    }
    case CCSTORE.CLEAR_CACHE: {
      return initialState;
    }
  }
  return state;
}
