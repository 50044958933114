import React, { FC, ReactChild } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin: 20px 0;
`;

const ChildWrap = styled.div`
  flex: 1;
  font-size: 25px;
  font-weight: 700;
`;

interface Props {
  buttons: ReactChild | undefined;
  className?: string;
}

const SubHeader: FC<Props> = ({ buttons, children, className }) => {
  return (
    <Wrapper>
      <ChildWrap className={className}>{children}</ChildWrap>
      {buttons}
    </Wrapper>
  );
};

export default SubHeader;
