import { UIState, reducer as uiReducer } from './ui/reducer';
import { reducer as uiSettings } from './uiSettings/reducer';
import { Zones, reducer as zonesReducer } from './zone/reducer';
import { reducer as cacheReducer } from './calcCache/reducer';
import { ApplicationState } from './definitions';
import { reducer as eventReducer } from './event/reducer';
import { reducer as compReducer } from './compareCache/reducer';

export const reducers = {
  uiSettings: uiSettings,
  uiState: uiReducer,
  zoneData: zonesReducer,
  calcCache: cacheReducer,
  eventData: eventReducer,
  compCache: compReducer,
};

export interface AppThunkAction<TAction> {
  (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}
