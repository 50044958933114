import moment from 'moment';
import { MiddlewareAPI, Dispatch } from 'redux';
import {
  pushCompareCacheData,
  pushCompareCacheDataFromDesc,
  pushCompareCachePending,
} from 'store/compareCache/action';

import { ApplicationState } from '../definitions';
import { selectUIStateRevView } from '../ui/selector';
import {
  calculateCompareSrcDates,
  pickValidCompareViewDataSet,
} from '../compareCache/selector';

import { execCompareUpdate } from './compareUpdateRequestHelper';

export const compareUpdatePoll = (
  api: MiddlewareAPI<Dispatch, any>,
  action: any
) => {
  // what do we need to check?!
  const newState = api.getState() as ApplicationState;

  const revenueView = selectUIStateRevView(newState);
  //console.log('RevenueView:' + revenueView);

  if (revenueView === 'regular' || revenueView === 'currProp') return; // we won't bother with trying to load anything unless we're in a comparision view! (Or if the comparison view is for previous/proposed values)
  if (!newState.calcCache || !newState.calcCache.cached) return; // no way to recalculate if we don't know what we want to cache for

  // TODO: see if the zoneIdSet stops including our cached values.. in that case.. invalidate it!
  //if (newState.calcCache && newState.calcCache.cached && newState.calcCache.cached.zoneIdSet)

  if (
    !pickValidCompareViewDataSet(
      newState,
      revenueView,
      newState.uiState.startDate,
      newState.uiState.endDate
    )
  ) {
    // we need to rebuild the cache!
    console.log('Rebuilding cache...:');
    const compareDates = calculateCompareSrcDates(
      revenueView,
      newState.uiState.startDate,
      newState.uiState.endDate
    );
    console.log(
      'Compare span:',
      newState,
      compareDates.start,
      compareDates.end
    );
    const desc = pushCompareCachePending(
      revenueView,
      newState.calcCache.cached?.zoneIdSet,
      newState.uiState.startDate,
      newState.uiState.endDate,
      compareDates.start,
      compareDates.end
    );

    if (
      newState.compCache.pending &&
      newState.compCache.pending[revenueView] &&
      newState.compCache.pending[revenueView]?.idkey === desc.idkey
    ) {
      console.log('Already pending request for this:' + desc.idkey);
      return;
    }

    execCompareUpdate(
      desc,
      (data) => {
        api.dispatch(pushCompareCacheDataFromDesc(revenueView, data));
      },
      (err) => {
        // TODO?!
        console.log('f&c reported a failure:', err);
      }
    );

    api.dispatch(desc);

    // api.dispatch(
    //   pushCompareCacheData(
    //     revenueView,
    //     newState.calcCache.cached?.zoneIdSet,
    //     newState.uiState.startDate,
    //     newState.uiState.endDate,
    //     compareDates.start,
    //     compareDates.end,

    //     newState.calcCache.cached.zoneIdSet.reduce((acc, zId, zidx) => {
    //       acc[zId] = [...new Array(compareDates.dayCount * 24)].map(
    //         (v, mi) => ({
    //           revenue: Math.random() * 10 + 1000 * (1 + zidx),
    //         })
    //       );
    //       return acc;
    //     }, {} as any)
    //   )
    //);
  }
  // passing by here we didn't need to do anything!
};
