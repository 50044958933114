import { useState } from 'react';
import { Event, ZoneEventData } from 'store/event/definitions';

export interface EventEditorRZState {
  routeData?: ZoneEventData;
  zoneData?: ZoneEventData[];
}

export interface EventEditorState {
  name: string;
  startDate: Date;
  endDate: Date;
  //zoneId: number | null;
  kind: string;
  //demand: number;
  //active: boolean;
  timeOfDay: string;
  //  id: number;
  //routeId: number;
  fullDay: boolean;
  //routeData?: ZoneEventData;
  //zoneData?: ZoneEventData[];
  rzData: EventEditorRZState;

  setName(value: string): void;
  setStartDate(value: Date): void;
  setEndDate(value: Date): void;
  setFullDay(value: boolean): void;
  //setZoneId(value: number | null): void;
  setKind(value: string): void;
  //setDemand(value: number): void;
  //setActive(value: boolean): void;
  setTimeOfDay(value: string): void;

  setRZData(v: EventEditorRZState): void;
  //setRouteData(v: ZoneEventData | undefined): void;
  //setZoneData(v: ZoneEventData[] | undefined): void;
  //setId(value: number): void;
  //setRouteId(value: number): void;
}

const useEventEditorState = (init: Event): EventEditorState => {
  const [name, setName] = useState<string>(init.name);
  const [startDate, setStartDate] = useState<Date>(new Date(init.startDate));
  const [endDate, setEndDate] = useState<Date>(new Date(init.endDate));
  const [fullDay, setFullDay] = useState<boolean>(init.fullDay);
  //const [zoneId, setZoneId] = useState<number | null>(init.zoneId);
  const [kind, setKind] = useState<string>(init.kind);
  //const [demand, setDemand] = useState<number>(init.demand);
  //const [active, setActive] = useState<boolean>(init.active);
  const [timeOfDay, setTimeOfDay] = useState<string>(init.timeOfDay);
  //const [id, setId] = useState<number>(init.id);
  //const [routeId, setRouteId] = useState<number>(init.routeId);
  // const [routeData, setRouteData] = useState(init.routeData);
  // const [zoneData, setZoneData] = useState(init.zoneData);
  const [rzData, setRZData] = useState<EventEditorRZState>({
    routeData: init.routeData,
    zoneData: init.zoneData,
  });

  return {
    name,
    startDate,
    endDate,
    //zoneId,
    kind,
    //demand,
    //active,
    timeOfDay,
    //routeId,
    //id,
    rzData,
    fullDay,

    setName,
    setStartDate,
    setEndDate,
    //setZoneId,
    setKind,
    //setDemand,
    //setActive,
    setTimeOfDay,
    //setId,
    //setRouteId,
    setRZData,
    setFullDay,
  };
};

export default useEventEditorState;
