import React from 'react';
// import { connect, useDispatch, useSelector } from 'react-redux';
// import Route from 'constants/Route';
// import {
//   ResponsiveContainer,
//   ComposedChart,
//   Bar,
//   Line,
//   XAxis,
//   YAxis,
//   CartesianGrid,
//   Legend,
//   Tooltip,
// } from 'recharts';
// import moment from 'moment';
// import { range } from 'util/range';
// import { selectCurrentZone } from 'store/zone/selector';
// import { zoneStoreApply } from 'store/zone/action';

// const baseUrl = document
//   .getElementsByTagName('base')[0]
//   .getAttribute('href') as string;

// const today = moment(new Date());

// export default () => {
//   const zone = useSelector(selectCurrentZone);
//   const dispatch = useDispatch();

//   const data = Array.from(range(0, 31)).map((_discarded, idx) => {
//     return {
//       date: today.add(idx, 'd').toISOString(),
//       duDemand: Math.floor(Math.random() * zone.aggressivity),
//       boDemand: Math.floor(Math.random() * zone.aggressivity * 1.3),
//       price: Math.floor(Math.random() * 3000) / 10,
//     };
//   });

//   return (
//     <div>
//       <div>JTest</div>
//       <input
//         type="range"
//         min="0"
//         max="100"
//         step="1"
//         value={zone.aggressivity}
//         onChange={(v) => {
//           console.log(v.target.value);
//           dispatch(
//             zoneStoreApply(zone.id, { aggressivity: parseInt(v.target.value) })
//           );
//         }}
//       />
//       <ResponsiveContainer width="100%" aspect={3}>
//         <ComposedChart
//           margin={{
//             top: 20,
//             right: 30,
//             left: 20,
//             bottom: 5,
//           }}
//           data={data}
//         >
//           <CartesianGrid />
//           <XAxis angle={45} />
//           <YAxis domain={[0, 150]} yAxisId="left" orientation="left" />
//           <YAxis domain={[0, 500]} yAxisId="right" orientation="right" />

//           <Bar
//             yAxisId="left"
//             dataKey="duDemand"
//             name="Driveups"
//             stackId="a"
//             fill="#ff00ff"
//           />
//           <Bar
//             yAxisId="left"
//             dataKey="boDemand"
//             name="Bookings"
//             stackId="a"
//             fill="#00ff00"
//           />
//           <Tooltip />
//           <Legend />
//           <Line
//             name="Prices"
//             yAxisId="right"
//             type="monotone"
//             dataKey="price"
//             stroke="#123456"
//           ></Line>
//         </ComposedChart>
//       </ResponsiveContainer>
//       <span>
//         Go to pricing<a href={baseUrl + Route.Pricing}>Pricing</a>{' '}
//       </span>
//     </div>
//   );
// };

import Revenue from '../Revenue';

export default Revenue;
