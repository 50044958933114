import {
  CompareDataSet,
  //  CompareDataSetDescription,
} from 'store/compareCache/definitions';
import { pushCompareCachePending } from '../compareCache/action';

type RequestDesc = Omit<ReturnType<typeof pushCompareCachePending>, 'type'>;

const pendingAborts = {} as { [key: string]: () => void };

export function execCompareUpdate(
  reqDesc: ReturnType<typeof pushCompareCachePending>,
  completion: (data: CompareDataSet) => void,
  err: (errMsg: string) => void
) {
  if (pendingAborts[reqDesc.compareType]) {
    pendingAborts[reqDesc.compareType]();
    delete pendingAborts[reqDesc.compareType];
  }

  let controller: null | AbortController = new AbortController();
  const { signal } = controller;

  pendingAborts[reqDesc.compareType] = () => {
    if (controller) {
      controller.abort();
      controller = null;
    }
  };

  fetch('/api/priceui/v3/comparefetch', {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify(reqDesc),
    signal,
  })
    .then((reqResp) => {
      if (reqResp.status === 401) {
        // need login
        window.location.href =
          '/user/login?returnUrl=' +
          encodeURIComponent(window.location.pathname.toString());
        return;
      } else if (reqResp.status !== 200) {
        alert('Api err:' + reqResp.statusText);
        throw new Error('Request error,' + reqResp.statusText);
      }

      return reqResp.json();
    })
    .then((jsonData) => {
      controller = null;
      completion(jsonData as CompareDataSet);
    })
    .catch((inErr) => err(inErr.toString()));

  return 123;
}
