import 'bootstrap/dist/css/bootstrap.css';

//import registerServiceWorker from './registerServiceWorker';
import APDemoIndex from './apdemo';
import RegularApp from './App';

if (window.location.pathname.startsWith('/apdemo')) {
  APDemoIndex();
} else {
  console.log('Not apdemo');
  RegularApp();
}
