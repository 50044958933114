import React from 'react';
import {
  ComposedChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import styled, { useTheme } from 'styled-components';
import {
  CustomLabel,
  MarginWrapper,
  Payload,
  TooltipWrapper,
} from '../GraphBase';
import { numToSpacedString } from '../GraphData';

export interface DataPoint {
  [key: string]: number | string;
}

export const GraphTop = styled.div`
  display: flex;
  flex-direction: row;
  gap: 40px;
  padding: 20px 0;
  justify-content: space-between;
  align-items: flex-start;
`;

export const LeftContent = styled.div`
  display: flex;
  gap: 20px;
`;
export const RightContent = styled.div`
  display: flex;
  gap: 40px;
`;

interface Props {
  data: DataPoint[];
  lines: { key: string; name: string; color: string }[];
  xDataKey: string;
  maxYValue?: number;
  xTicks?: number;
  withDots?: boolean;
  strokeWidth?: number;
  yTickFormatter?: (value: any) => string;
  tooltip?: {
    [key: string]: {
      title: string;
      color: string;
      formatter: (value: any) => string | (string | JSX.Element)[];
    };
  };
  yTicks?: (number | string)[];
  xTickFormatter?: (value: any) => string;
}

const ComparisonLineChart: React.FC<Props> = ({
  data,
  lines,
  xDataKey,
  maxYValue,
  withDots = false,
  yTickFormatter,
  xTicks,
  strokeWidth = 1,
  tooltip = {},
  yTicks = undefined,
  xTickFormatter,
}) => {
  const theme = useTheme();
  const maxAmount = Math.max(
    ...data.flatMap((item) =>
      lines.map((line) => {
        var value = item[line.key];
        return isNaN(value as number) ? 0 : Number(item[line.key]);
      })
    )
  );

  var factor = Math.pow(10, Math.floor(Math.log10(maxAmount)));

  let yAxisTicks = factor * Math.floor(maxAmount / factor + 1);
  if (!!maxYValue) {
    yAxisTicks = maxYValue;
  }

  const CustomTooltip: React.FC<any> = (props) => {
    const { active, payload } = props;
    if (active && payload && payload.length) {
      const data = payload[0];

      return (
        <TooltipWrapper>
          {Object.keys(data.payload).map((key) => {
            var _tooltip = tooltip[key];
            if (!!_tooltip) {
              var toolTip = _tooltip.formatter(data.payload[key] ?? '-');
              if (typeof toolTip === 'string') {
                return (
                  <Payload key={key} color={_tooltip.color}>
                    {_tooltip.title}: {toolTip}
                  </Payload>
                );
              } else {
                return toolTip.map((item, index) => {
                  if (typeof item === 'string') {
                    return (
                      <Payload key={index} color={_tooltip.color}>
                        {_tooltip.title}: {item}
                      </Payload>
                    );
                  } else {
                    return item;
                  }
                });
              }
            }
            return (
              <Payload key={key} color={data.color}>
                {key}: {data.payload[key] ?? '-'}
              </Payload>
            );
          })}
        </TooltipWrapper>
      );
    }

    return null;
  };

  return (
    <MarginWrapper>
      <ResponsiveContainer width="100%" height={600}>
        <ComposedChart
          data={data}
          margin={{ top: 20, right: 0, left: 0, bottom: 0 }}
        >
          <CartesianGrid stroke={theme.palette.common.lightBorder} />
          <XAxis
            textAnchor="start"
            dataKey={xDataKey}
            tickFormatter={(value) => {
              if (!!xTickFormatter) {
                return xTickFormatter(value);
              }
              return `${value}`;
            }}
            tick={{
              fill: theme.palette.text.fg,
              textAnchor: 'middle',
            }}
            stroke={theme.palette.common.lightBorder}
          />
          <YAxis
            yAxisId={'left'}
            orientation="left"
            domain={[0, yAxisTicks]}
            ticks={
              !!yTicks
                ? yTicks
                : Array.from({ length: 10 }, (_, index) =>
                    ((index + 1) * (yAxisTicks / 10)).toFixed(1)
                  )
            }
            tickFormatter={
              !!yTickFormatter ? yTickFormatter : numToSpacedString
            }
            tick={{ fill: theme.palette.text.fg }}
            stroke={theme.palette.common.lightBorder}
          />
          <YAxis
            yAxisId="right"
            orientation="right"
            axisLine={{ stroke: '#ededed' }}
          />
          {lines.map((line, index) => (
            <Line
              type="line"
              yAxisId="left"
              key={line.key}
              dataKey={line.key}
              name={line.name}
              stroke={line.color}
              fill={line.color}
              connectNulls={true}
              strokeWidth={strokeWidth}
            ></Line>
          ))}
          <Tooltip content={<CustomTooltip />} />
          <Legend
            wrapperStyle={{ textAlign: 'center', marginTop: 10 }}
            formatter={(value, entry, index) => (
              <CustomLabel>{value}</CustomLabel>
            )}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </MarginWrapper>
  );
};

export default ComparisonLineChart;
