import React, { useEffect } from 'react';

//Using generic line charts component to display the data
import LineChart, {
  DataPoint,
  GraphTop,
  LeftContent,
  RightContent,
} from 'components/Graph/GenericLineCharts';

import styled from 'styled-components';
import { BookingPatternResponse, GraphController } from 'api/graph';
import Main from 'components/Main';
import Label from 'components/input/Label';
import PTDatePicker from 'components/DateRangePicker/DatePicker';
import moment from 'moment';
import {
  selectAvailableFacilityZones,
  selectAvailableZones,
  selectCurrentZones,
} from 'store/zone/selector';
import { useSelector } from 'react-redux';
import Input from 'components/input/Input';
import Select from 'components/input/Select';
import GraphLoader from 'components/Loader/graphLoader';
import Loader from 'components/Loader';
import ExportButton from 'util/excel';
import { getDaysBetweenDates, getSameDayLastYear } from 'util/date';
import { getColorHash } from 'pages/Occupancy';

const BookingPattern: React.FC = () => {
  var graphController = new GraphController();
  const [result, setResult] = React.useState<BookingPatternResponse>();
  const [compareResult, setCompareResult] =
    React.useState<BookingPatternResponse>();
  const [startDate, setStartDate] = React.useState<moment.Moment>(
    moment().subtract(1, 'day')
  );
  const [los, setLos] = React.useState<number>(21);
  const activeZones = useSelector(selectCurrentZones);
  const [showCompare, setShowCompare] = React.useState<boolean>(false);
  const [compareDate, setCompareDate] = React.useState<moment.Moment>(
    getSameDayLastYear(moment().subtract(1, 'day'))
  );

  console.log(activeZones);

  const [zoneIds, setZoneIds] = React.useState<number[]>([]);

  useEffect(() => {
    if (activeZones && activeZones.length > 0) {
      setZoneIds(activeZones.map((zone) => zone.id));
    }
  }, [activeZones]);

  useEffect(() => {
    if (zoneIds.length > 0) {
      setResult(undefined);
      setTimeout(() => {
        fetchData();
      }, 0);
    }
  }, [startDate, zoneIds, los]);

  useEffect(() => {
    if (zoneIds.length > 0 && showCompare) {
      setCompareResult(undefined);
      setTimeout(() => {
        fetchCompareData();
      }, 0);
    }
  }, [compareDate, showCompare, zoneIds]);

  const fetchData = async () => {
    var result = await graphController.getBookingPattern({
      StartDate: startDate.format('YYYY-MM-DD'),
      ZoneIds: zoneIds,
      LOS: los,
    });
    setResult(result);
  };

  const fetchCompareData = async () => {
    var result = await graphController.getBookingPattern({
      StartDate: compareDate.format('YYYY-MM-DD'),
      ZoneIds: zoneIds,
      LOS: los,
    });
    setCompareResult(result);
  };

  var graphData = [] as DataPoint[];
  zoneIds.forEach((zoneId) => {
    if (!!result && !!result.data[zoneId]) {
      const data = result.data[zoneId];
      Object.keys(data).forEach((curr) => {
        var point = graphData.find((x) => x.LOS === curr);

        if (zoneIds.length === 1) {
          if (!point) {
            graphData.push({ LOS: curr, Percent: data[curr].toFixed(2) });
          } else {
            point['Percent'] = data[curr].toFixed(2);
          }
        } else {
          var key = `Percent_${zoneId}` as keyof DataPoint;
          if (!point) {
            graphData.push({ LOS: curr, [key]: data[curr].toFixed(2) });
          } else {
            point[key] = data[curr].toFixed(2);
          }
        }
      });
    }

    if (!!compareResult && compareResult.data[zoneId] && showCompare) {
      const cdata = compareResult.data[zoneId];
      Object.keys(cdata).forEach((curr) => {
        var point = graphData.find((x) => x.LOS === curr);

        if (zoneIds.length === 1) {
          if (!point) {
            graphData.push({ LOS: curr, PercentC: cdata[curr].toFixed(2) });
          } else {
            point['PercentC'] = cdata[curr].toFixed(2);
          }
        } else {
          var key = `PercentC_${zoneId}`;
          if (!point) {
            graphData.push({ LOS: curr, [key]: cdata[curr].toFixed(2) });
          } else {
            point[key] = cdata[curr].toFixed(2);
          }
        }
      });
    }
  });

  if (graphData.length !== 0) {
    [...Array(los).keys()].forEach((x) => {
      if (!graphData.find((data) => data.LOS === x.toString())) {
        graphData.push({ LOS: x.toString() });
      }
    });
  }
  graphData = graphData.sort((a, b) => Number(a.LOS) - Number(b.LOS));

  var lines = [
    {
      key: 'Percent',
      name: 'Prior days',
      color: 'green',
    },
    {
      key: 'PercentC',
      name: 'Prior days prev. period',
      color: 'red',
    },
  ] as any[];

  var toolTip = {
    Percent: {
      title: 'Percent of bookings',
      color: 'green',
      formatter: (value: any) => {
        return `${value}%`;
      },
    },
    PercentC: {
      title: 'Percent of bookings prev. period',
      color: 'red',
      formatter: (value: any) => {
        return `${value}%`;
      },
    },
  } as {
    [key: string]: {
      title: string;
      color: string;
      formatter: (value: any) => any;
    };
  };

  if (zoneIds.length > 1 && graphData!!.length > 0) {
    lines = [];
    toolTip = {};
    Object.keys(graphData[0]).forEach((curr) => {
      if (curr != 'LOS') {
        var zoneSplit = curr.split('_');
        var zoneId = zoneSplit[1];
        var version = zoneSplit[0];
        var zone = activeZones.find((x) => x.id == Number(zoneId));
        toolTip[curr] = {
          title:
            version === 'Percent'
              ? `Percent of bookings ${zone?.name}`
              : `Prev. percent of booking ${zone?.name}`,
          color: getColorHash(`${curr.split('_')[0]}_${zone?.gid}`),
          formatter: (value: any) => {
            return `${value}%`;
          },
        };
        lines.push({
          key: curr,
          name:
            version === 'Percent'
              ? `Prior days ${zone?.name}`
              : `Prior day prev. period ${zone?.name}`,
          color: getColorHash(`${curr.split('_')[0]}_${zone?.gid}`),
        });
      }
    });
  }

  return (
    <Main>
      <GraphTop>
        <LeftContent>
          <Label label="Arrival date">
            <PTDatePicker
              dateFormat="yyyy-MM-dd"
              onChange={(date: Date) => {
                console.log(date);
                var daysBetweenCompate = getDaysBetweenDates(
                  compareDate.toDate(),
                  startDate.toDate()
                );
                setStartDate(moment(date));
                setCompareDate(
                  moment(date).subtract(daysBetweenCompate, 'days')
                );
              }}
              selected={startDate.toDate()}
              dropdownMode="select"
              selectsStart
              showMonthDropdown
              showYearDropdown
            />
          </Label>
          <Label label="Prior days">
            <Input
              type="number"
              onChange={(eve) => {
                var value = Number(eve.target.value);
                setLos(value);
              }}
              value={los}
            />
          </Label>
          <input
            style={{ marginRight: '-7px', marginTop: '9px' }}
            type="checkbox"
            checked={showCompare}
            onChange={(evt) => {
              setShowCompare(!showCompare);
            }}
          />
          <Label label="Compare from date">
            <PTDatePicker
              dateFormat="yyyy-MM-dd"
              disabled={!showCompare}
              onChange={(date: Date) => {
                setCompareDate(moment(date));
              }}
              selected={compareDate.toDate()}
              dropdownMode="select"
              selectsStart
              showMonthDropdown
              showYearDropdown
            />
          </Label>
        </LeftContent>
        <RightContent>
          <ExportButton
            name={`Booking pace`}
            data={{
              data: graphData.map((f) => {
                var obj = {
                  Date: moment(startDate)
                    .subtract(Number(f.LOS), 'days')
                    .format('YYYY-MM-DD'),
                  PreviousDate: moment(compareDate)
                    .subtract(Number(f.LOS), 'days')
                    .format('YYYY-MM-DD'),
                } as any;
                obj['Prior days'] = f.LOS;
                if (zoneIds.length === 1) {
                  obj['Percent'] = f.Percent;
                  obj['Percent Compare'] = f.PercentC;
                } else {
                  Object.keys(f).forEach((curr) => {
                    if (curr != 'LOS') {
                      var zoneSplit = curr.split('_');
                      var zoneId = zoneSplit[1];
                      var version = zoneSplit[0];
                      var zone = activeZones.find(
                        (x) => x.id == Number(zoneId)
                      );
                      if (version === 'Percent') {
                        obj[`${zone?.name}`] = f[curr];
                      } else {
                        obj[`${zone?.name} Compare`] = f[curr];
                      }
                    }
                  });
                }
                return obj;
              }),
            }}
          />
        </RightContent>
      </GraphTop>
      <div style={{ position: 'relative' }}>
        <LineChart
          xTicks={los}
          maxYValue={110}
          yTicks={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
          yTickFormatter={(value: any) => {
            return `${value}%`;
          }}
          xTickFormatter={(value: any) => {
            var days = Number(value);
            return moment(startDate).subtract(days, 'days').format('DD/MM');
          }}
          data={graphData}
          tooltip={{
            LOS: {
              title: 'Prior days',
              color: 'black',
              formatter: (value: any) => {
                return `${value} days`;
              },
            },
            ...toolTip,
          }}
          lines={lines}
          xDataKey="LOS"
        />
        {graphData.length === 0 && (
          <div
            style={{
              position: 'absolute',
              left: 0,
              top: 0,
              right: 0,
              bottom: 0,
              backgroundColor: '#ffffff',
              opacity: 0.35,
            }}
          >
            <Loader title={'Could not find data'} forceError={!!result} />
          </div>
        )}
      </div>
    </Main>
  );
};

export default BookingPattern;
