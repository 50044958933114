import React, { FC } from 'react';
import styled from 'styled-components';

const Clicker = styled.label`
  display: flex;
  flex-direction: row;
  margin: 0;

  input {
    position: fixed;
    top: -100px;
    left: -100px;
  }

  div {
    position: relative;
    height: 20px;
    width: 20px;
    margin-right: 5px;

    border: 1px solid ${({ theme }) => theme.palette.text.fg};
    border-radius: 50%;
  }

  div:before {
    content: '';
    position: absolute;
    top: 10px;
    left: 10px;
    width: 0px;
    height: 0px;
    transition: width 0.2s, height 0.2s, top 0.2s, left 0.2s;
  }

  input:checked + div:before {
    top: 5px;
    left: 5px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: ${({ theme }) => theme.palette.secondary.bg};
  }
`;

interface Props extends Omit<React.HTMLProps<HTMLInputElement>, 'type'> {}

const Radio: FC<Props> = ({ children, ...inputProps }) => {
  return (
    <>
      <Clicker>
        <input type="radio" {...inputProps} />
        <div />
        {children}
      </Clicker>
    </>
  );
};

export default Radio;
