import { css } from 'styled-components';

const baseInputStyle = css`
  padding: 5px 10px;
  border: 0;
  border-bottom: 2px solid ${({ theme }) => theme.palette.text.fg};
  background-color: ${({ theme }) => theme.palette.grey.bg};
  color: ${({ theme }) => theme.palette.grey.fg};
  transition: background-color 0.2s, border-color 0.2s;

  &:focus:not(:disabled),
  &:hover:not(:disabled) {
    color: ${({ theme }) => theme.palette.secondaryLight.fg};
    background-color: ${({ theme }) => theme.palette.secondaryLight.bg};
    border-color: ${({ theme }) => theme.palette.secondary.bg};
  }

  &:focus {
    outline: 0;
  }

  &:disabled {
    border-bottom-color: ${({ theme }) => theme.palette.disabled.fg};
    color: ${({ theme }) => theme.palette.disabled.fg};
    background: transparent;
  }
`;

export default baseInputStyle;
