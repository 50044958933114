//import { SSL_OP_SSLEAY_080_CLIENT_DH_BUG } from 'constants';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store/definitions';
import { usSetFailedToFetch } from 'store/ui/action';
import { selectAvailableFacilityZones } from 'store/zone/selector';
import { Palette } from 'styled';
import styled, { css, useTheme, withTheme } from 'styled-components';
import { getSort } from 'util/pricing';
import {
  Pricing,
  PricingRange,
  Zone,
  ZoneFlag,
} from '../../store/zone/reducer';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 300px;
  max-width: 100%;
`;

const GraphWrapper = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: auto 1.8em;
  grid-auto-flow: column;
  grid-gap: 0.5em;
  grid-auto-columns: minmax(80px, 120px);
  max-width: 100%;
  //display: flex;
`;

// Column was used for sizing elements previously,
//  the sizing is now handled directly by the grid-parent since it will give us alignments between elements.
// const Column = styled.div`
//   flex: 1;
//   display: flex;
//   flex-direction: column;
//   justify-content: flex-end;
//   margin: 0 10px;
//   min-width: 120px;
//   font-size: 16px;
//   align-items: center;
// `;

const ZoneText = styled.div`
  margin-top: 20px;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  word-wrap: none;
`;

const DataValue = styled.div<{
  hasZone: boolean;
  color: string;
  top: number;
  bottom: number;
}>`
  background: ${(props) => props.color};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  width: 80%;
  margin: 0 auto;
  left: 0;
  right: 0;
  /* min-height: 48px; */
  align-items: center;
  transition: top 1s, bottom 1s;
  ${(props) =>
    !props.hasZone &&
    css`
      top: ${props.top}%;
      bottom: ${props.bottom}%;
    `}
  ${(props) =>
    props.hasZone &&
    css`
      top: ${props.top}px;
      bottom: ${props.bottom}px;
    `}
`;

const Value = styled.div`
  color: white;
  font-weight: bold;
  text-shadow: -1px 1px 0 #191919, 1px 1px 0 #191919, 1px -1px 0 #191919,
    -1px -1px 0 #191919;
`;

const DataWrapper = styled.div`
  position: relative;
  flex: 1 1 100%;
  width: 100%;
`;

const ValueWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 48px;
`;

interface Props {
  segment: string;
  duration: string;
  zone?: Zone;
}

interface GraphData {
  zone: string;
  minValue: number;
  maxValue: number;
  id: number;
  duration: string;
  noValue: boolean;
  title: string;
  sort: number;
}

const indexMapping = {} as { [key: number]: string };

const PricingParamsGraph: React.FC<Props> = (props) => {
  const theme = useTheme();

  const zones = useSelector(selectAvailableFacilityZones);
  const [graphData, setGraphData] = useState<GraphData[]>([]);
  const [isEvCharging, _] = useState<boolean>(
    !!props.zone && !!props.zone.zoneFlags!.find((f) => f === ZoneFlag.Electric)
  );

  useEffect(() => {
    let data = [] as GraphData[];
    if (!props.zone) {
      data = zones.reduce((acc, curr, index) => {
        const obj = {
          zone: curr.name,
          id: curr.id,
          noValue: false,
          duration: index.toString() ?? 'sixth',
          sort: index,
          title: curr.name,
        } as GraphData;

        var charge = {
          min: 0,
          max: 0,
        } as {
          min: number;
          max: number;
        };

        var prices = curr.pricings.find((x) => x.id === curr.selectedPricing);

        var surcharge = prices?.prices.find(
          (f) => f.duration === props.duration && f.segment === 'surcharge'
        );

        if (surcharge) {
          charge.min += surcharge.min;
          charge.max += surcharge.max;
        }
        if (prices) {
          prices.prices.forEach((f) => {
            if (
              f.segment === props.segment &&
              f.duration === props.duration &&
              !f.groupId
            ) {
              obj.minValue = f.min + charge.min;
              obj.maxValue = f.max + charge.max;
            }
          });
        }
        if (!obj.minValue && !obj.maxValue) {
          obj.minValue = 0;
          obj.maxValue = 0;
          obj.noValue = true;
        }
        acc.push(obj);
        return acc;
      }, [] as GraphData[]);
      data.sort((a, b) => {
        if (a.noValue && b.noValue) {
          return 0;
        }
        if (a.noValue && !b.noValue) {
          return 1;
        }
        if (!a.noValue && b.noValue) {
          return -1;
        }
        return a.minValue < b.minValue ? -1 : 1;
      });
    } else {
      const pricing = props.zone.pricings.find(
        (f) => f.id === props.zone?.selectedPricing
      );
      if (pricing) {
        data = pricing?.prices.reduce((acc, curr) => {
          if (curr.segment === props.segment && curr.active) {
            var chargePrices = {
              min: 0,
              max: 0,
            };
            var surcharge = pricing.prices.find(
              (f) => f.duration === curr.duration && f.segment === 'surcharge'
            );
            if (surcharge) {
              chargePrices.min += surcharge.min;
              chargePrices.max += surcharge.max;
            }
            acc.push({
              minValue: curr.min + chargePrices.min,
              maxValue: curr.max + chargePrices.max,
              zone: curr.duration,
              id: curr.id,
              duration: curr.duration,
              noValue: false,
              title: getText(pricing!, curr),
              sort: getSort(curr),
            });
          }
          return acc;
        }, [] as GraphData[]);
        data.sort((a, b) => a.sort - b.sort);
      }
      // data = ['hour', 'day', 'week'].map((x) => {
      //   const _dataValue = data.find((f) => f.duration == x);
      //   if (!_dataValue) {
      //     return {
      //       zone: x,
      //       id: 0,
      //       duration: x,
      //       minValue: 0,
      //       maxValue: 0,
      //       noValue: true,
      //     };
      //   }
      //   return _dataValue;
      // }) as GraphData[];
    }
    if (graphData.length === 0) {
      setGraphData(
        data.map((f) => {
          return {
            ...f,
            minValue: 0,
            maxValue: 0,
          };
        })
      );
      setTimeout(() => {
        setGraphData(data);
      }, 0);
    } else {
      setGraphData(data);
    }
  }, [props.zone, props.segment, props.duration, zones]);

  const getColor = (index: string): string => {
    switch (index) {
      case 'hour':
      case '0':
        return theme.palette.primary.bg;
      case 'day':
      case '1':
        return theme.palette.tertiary.bg;
      case 'week':
      case '2':
        return theme.palette.common.orange;
      case '3':
        return theme.palette.secondary.bg;
      case '4':
        return '#BAABDA';
      case '5':
        return '#B4CFB0';
      case '6':
        return '#789395';
      case '7':
        return '#BB6464';
      case '8':
        return '#C3DBD9';
      case '9':
        return '#D3E4CD';
      case '10':
        return '#D1D1D1';
      case '11':
        return '#FFF89A';
      default:
        return 'white';
    }
  };

  const getText = (price: Pricing, pricing: PricingRange): string => {
    if (pricing.groupId !== null) {
      var group = price.priceGroups.find((f) => f.id === pricing.groupId);
      if (!!group) {
        return group.name;
      }
    }
    switch (pricing.duration) {
      case 'hour':
        return 'Hour';
      case 'day':
        return 'Day';
      case 'week':
        return 'Week';
      default:
        return pricing.duration;
    }
  };

  var minAndMax = graphData.reduce((acc, curr) => {
    if (curr.noValue) return acc;
    if (!acc.minValue) {
      acc.minValue = curr.minValue - 2;
    } else if (curr.minValue < acc.minValue) {
      acc.minValue = curr.minValue - 2;
    }
    if (!acc.maxValue) {
      acc.maxValue = curr.maxValue + 2;
    } else if (acc.maxValue < curr.maxValue) {
      acc.maxValue = curr.maxValue + 2;
    }

    return acc;
  }, {} as { minValue: number; maxValue: number; unit: number });

  minAndMax.unit = 100 / (minAndMax.maxValue - minAndMax.minValue);

  return (
    <Wrapper>
      <GraphWrapper>
        {graphData.map((f, index) => (
          <React.Fragment key={index}>
            <DataWrapper>
              <DataValue
                hasZone={props.zone !== undefined}
                bottom={
                  f.noValue
                    ? 0
                    : (f.minValue - minAndMax.minValue) * minAndMax.unit
                }
                top={
                  f.noValue
                    ? 0
                    : (minAndMax.maxValue - f.maxValue) * minAndMax.unit
                }
                color={f.noValue ? 'transparent' : getColor(f.duration)} //Max amount is 5 atm after instructions from Anton
              >
                {f.minValue !== 0 && f.maxValue !== 0 && (
                  <Value>{f.maxValue}</Value>
                )}
                {f.minValue !== 0 && f.maxValue !== 0 && (
                  <Value>{f.minValue}</Value>
                )}
              </DataValue>
            </DataWrapper>
            <ZoneText>{f.title}</ZoneText>
          </React.Fragment>
        ))}
      </GraphWrapper>
    </Wrapper>
  );
};

export default PricingParamsGraph;
