import React, { FC } from 'react';
import Button, { ButtonColors } from 'components/input/Button';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store/definitions';

const Wrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: -25px;
  position: relative;
  z-index: 1;
`;

interface Props {
  onPreviousClick(): void;
  onNextClick(): void;
  nextLabel: string;
  prevLabel: string;
}

const DateNavigation: FC<Props> = ({
  nextLabel,
  prevLabel,
  onNextClick,
  onPreviousClick,
}) => {
  const { minStartDate, maxEndDate } = useSelector(
    (state: ApplicationState) => state.uiState
  );

  console.log(minStartDate, prevLabel, nextLabel, maxEndDate);
  return (
    <Wrap>
      {prevLabel > minStartDate && prevLabel !== '' ? (
        <Button color={ButtonColors.link} onClick={onPreviousClick}>
          &lt; {prevLabel}
        </Button>
      ) : (
        <div />
      )}
      {nextLabel < maxEndDate && nextLabel !== '' && (
        <Button color={ButtonColors.link} onClick={onNextClick}>
          {nextLabel} &gt;
        </Button>
      )}
    </Wrap>
  );
};

export default DateNavigation;
