import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  Pricing,
  PricingRange,
  Zone,
  ZoneFlag,
} from '../../store/zone/reducer';
import List from 'components/List';
import { PricingRangeComponent } from '.';
import { generatePriceRange, getSort } from 'util/pricing';

const Wrapper = styled.div`
  display: flex;
`;

const ZoneColumn = styled.div`
  flex: 1;
  padding: 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  label {
    font-weight: bold;
    text-decoration: underline;
  }
`;
const ZonePrice = styled.div`
  color: ${({ theme }) => theme.palette.primary.bg};
  font-weight: bold;
  :hover {
    color: rgb(137, 87, 160);
    -webkit-text-decoration: underline;
    text-decoration: underline;
    -webkit-text-decoration-color: rgb(117, 210, 207);
    text-decoration-color: rgb(117, 210, 207);
    -webkit-text-decoration-thickness: 2px;
    text-decoration-thickness: 2px;
    text-underline-position: under;
    cursor: pointer;
  }
`;

const ActivePricingProduct = styled.a``;

interface Props {
  zones: Zone[];
  showPricingModal: (id: number, zone?: Zone) => void;
  segment: string;
  duration: string;
}

interface Segment {
  header: string;
  sort: number;
  duration: PricingRange['duration'];
  [key: number]: {
    id: number;
    isEvChage: boolean;
    groupId?: number | null;
    duration: string;
  };
}

interface RowData {
  zoneName: string;
  pricingId: number;
  pricingName: string;
  pricing: Pricing;
  zone: Zone;
}

const ZoneWrapper: React.FC<Props> = (props) => {
  const { zones, showPricingModal } = props;

  const [headerSegment, setHeaderSegment] = useState<Segment[]>([]);
  const [rowData, setRowData] = useState<RowData[]>([]);

  useEffect(() => {
    var _headerSegment: Segment[] = [];
    let _rowData = zones.map((f) => {
      var isEvCharge = !!f.zoneFlags!.find((f) => f === ZoneFlag.Electric);
      var pricing = f.pricings.find((x) => x.id === f.selectedPricing)!;
      if (!pricing) {
        return {
          zoneName: f.name,
        };
      }

      const localSegments: Segment[] = pricing.prices
        .filter((f) => f.segment === props.segment)
        .reduce((acc, curr) => {
          if (!!curr.groupId) {
            var group = pricing.priceGroups.find((x) => x.id === curr.groupId);
            return [
              ...acc,
              {
                header: `${group!.name} span`,
                duration: curr.duration,
                sort: getSort(curr),
                [f.id]: {
                  isEvChage: isEvCharge,
                  groupId: group!.id,
                  id: curr.id,
                  duration: curr.duration,
                },
              },
            ];
          }
          return [
            ...acc,
            {
              header: `${curr.duration[0].toUpperCase()}${curr.duration.substring(
                1
              )} span`,
              duration: curr.duration,
              sort: getSort(curr),
              [f.id]: {
                isEvChage: isEvCharge,
                groupId: curr.groupId,
                id: curr.id,
                duration: curr.duration,
              },
            },
          ];
        }, [] as Segment[]);
      _headerSegment = localSegments.reduce((acc, curr) => {
        var innerSegment = _headerSegment.find((f) => f.header === curr.header);
        if (!innerSegment) {
          return [...acc, curr];
        }
        innerSegment[f.id] = curr[f.id];
        return [...acc, innerSegment];
      }, [] as Segment[]);
      console.log(_headerSegment);

      return {
        zoneName: f.name,
        pricingId: pricing.id,
        pricingName: pricing.name,
        pricing: pricing,
        zone: f,
      };
    }) as RowData[];
    setHeaderSegment(_headerSegment);
    setRowData(_rowData);
  }, [props.duration, props.segment]);

  // rowData = rowData.sort((a, b) => {
  //   if (props.duration === 'hour') {
  //     if (!a.hour && !b.hour) {
  //       return 0;
  //     }
  //     if (a.hour && !b.hour) {
  //       return -1;
  //     }
  //     if (!a.hour && b.hour) {
  //       return 1;
  //     }
  //     return a.hour!.min < b.hour!.min ? -1 : 1;
  //   }
  //   if (props.duration === 'day') {
  //     if (!a.day && !b.day) {
  //       return 0;
  //     }
  //     if (a.day && !b.day) {
  //       return -1;
  //     }
  //     if (!a.day && b.day) {
  //       return 1;
  //     }
  //     return a.day!.min < b.day!.min ? -1 : 1;
  //   }
  //   if (props.duration === 'week') {
  //     if (!a.week && !b.week) {
  //       return 0;
  //     }
  //     if (a.week && !b.week) {
  //       return -1;
  //     }
  //     if (!a.week && b.week) {
  //       return 1;
  //     }
  //     return a.week!.min < b.week!.min ? -1 : 1;
  //   }

  //   return 0;
  // });

  return (
    <List
      columnSettings={[
        {
          header: 'Zone name',
          display: ({ row }) => {
            return <span>{row.zoneName}</span>;
          },
        },
        {
          header: 'Pricing product',
          display: ({ row }) => {
            return (
              <ZonePrice
                onClick={(evt) => {
                  showPricingModal(row.pricingId, row.zone);
                  evt.preventDefault();
                }}
              >
                {row.pricingName}
              </ZonePrice>
            );
          },
        },

        ...headerSegment
          .sort((a, b) => a.sort - b.sort)
          .map((x) => {
            return {
              header: x.header,
              display: ({
                row,
              }: {
                row: {
                  zoneName: string;
                  pricingId: number;
                  pricingName: string;
                  pricing: Pricing;
                  zone: Zone;
                };
              }) => {
                var _prop = x[row.zone.id];
                var pricing = row.pricing.prices.find(
                  (f) =>
                    f.active &&
                    f.duration === _prop.duration &&
                    f.segment === props.segment &&
                    f.groupId === _prop.groupId
                );
                var { range, chargeRange } = generatePriceRange(
                  pricing,
                  x.duration,
                  _prop.isEvChage
                    ? row.pricing.prices.filter(
                        (f) => f.segment === 'surcharge'
                      )
                    : []
                );
                return (
                  <PricingRangeComponent
                    range={range}
                    chargeRange={chargeRange}
                  />
                );
              },
            };
          }),
        // {
        //   header: 'Hour span',
        //   display: ({ row }) => {
        //     return <PricingRangeComponent range={row.hour} />;
        //   },
        // },
        // {
        //   header: 'Day span',
        //   display: ({ row }) => <PricingRangeComponent range={row.day} />,
        // },
        // {
        //   header: 'Week span',
        //   display: ({ row }) => <PricingRangeComponent range={row.week} />,
        // },
      ]}
      rows={rowData}
    />
  );
};

export default ZoneWrapper;
