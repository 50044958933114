import React, { FC, useCallback, useRef } from 'react';
import styled, { useTheme } from 'styled-components';

// Reload comment
const Wrapper = styled.div`
  position: relative;
  height: 20px;
`;

const FilledTrack = styled.div`
  display: inline-block;
  height: 1px;
  border-top: 1px solid ${({ theme }) => theme.palette.primary.bg};
  transition: width 0.1s;
`;

const Thumb = styled.div<{ defaulted: boolean }>`
  display: inline-flex;
  justify-content: center;
  height: 20px;
  width: 20px;
  margin: 0 -10px;
  margin-bottom: -10px;
  position: relative;
  z-index: 1;

  border-radius: 10px;
  background-color: ${({ theme, defaulted }) =>
    defaulted ? theme.palette.grey.bg : theme.palette.primary.bg};
  border-style: ${({ defaulted }) => (defaulted ? 'inset' : 'hidden')};
  border-width: ${({ defaulted }) => (defaulted ? '1px' : 0)};
  border-color: ${({ theme, defaulted }) => theme.palette.grey.fg};

  /* value display */
  & > div {
    display: none;
    position: fixed;
    margin-top: 30px;
    //top: 30px;
    padding: 5px 10px;
    min-width: 30px;

    user-select: none;
    border-radius: 2px;
    background: ${({ theme }) => theme.palette.common.darkOverlay};
    color: ${({ theme }) => theme.palette.common.white};
    white-space: nowrap;
    font-weight: normal;
    font-size: 16px;

    &::before {
      content: '';
      position: absolute;
      top: -20px;
      left: calc(50% - 10px);
      right: calc(50% - 10px);
      border: 10px solid transparent;
      border-bottom-color: ${({ theme }) => theme.palette.common.darkOverlay};
    }
  }

  &:hover div {
    display: block;
  }
`;

const Notch = styled.div`
  position: absolute;
  top: 10px;
  height: 14px;
  width: 1px;
  border-right: 1px solid ${({ theme }) => theme.palette.text.fg};
`;

const EmptyTrack = styled.div`
  display: inline-block;
  height: 1px;
  border-top: 1px solid ${({ theme }) => theme.palette.common.lightBorder};
  transition: width 0.1s;
`;

const MinMaxLabels = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
`;

const MinMaxLabel = styled.span``;

interface Props {
  value: number;
  suggestedValue?: number;
  min: number;
  max: number;
  stepSize: number;
  onChange(value: number): void;
  valueDisplayFormatter?(value: number, suggestedValue?: number): string;

  minLabel?: string;
  maxLabel?: string;
}

const useBound = <T extends any>(toBind: T) => {
  const bound = useRef(toBind);
  bound.current = toBind;
  return bound;
};

const Range: FC<Props> = ({
  min,
  max,
  stepSize,
  value,
  suggestedValue,
  onChange,
  valueDisplayFormatter = (v) => String(v),
  minLabel,
  maxLabel,
}) => {
  // default to suggestedValue if none exists
  value = value ?? suggestedValue;

  const wrapperRef = useRef<HTMLDivElement>(null);
  const filledTrackRef = useRef<HTMLDivElement>(null);
  const emptyTrackRef = useRef<HTMLDivElement>(null);
  const valueDisplayRef = useRef<HTMLDivElement>(null);
  const dragStartPos = useRef<number>();

  const percentFilled = useBound(((value - min) / (max - min)) * 100);
  const minRef = useBound(min);
  const maxRef = useBound(max);
  const onChangeRef = useBound(onChange);
  const stepSizeRef = useBound(stepSize);
  const stepPercentSize = useBound((stepSize / (max - min)) * 100);

  const theme = useTheme();

  const calcValues = useCallback((percentChange: number) => {
    let percent =
      Math.round(
        (percentFilled.current + percentChange) / stepPercentSize.current
      ) * stepPercentSize.current;
    percent = Math.min(100, Math.max(0, percent));

    const value =
      Math.round(
        ((maxRef.current - minRef.current) * (percent / 100) + minRef.current) /
          stepSizeRef.current
      ) * stepSizeRef.current;

    return { percent, value };
  }, []);

  const handleMouseMove = useCallback((eve: MouseEvent) => {
    const percentChange =
      ((eve.screenX - dragStartPos.current!) /
        wrapperRef.current!.clientWidth) *
      100;

    const { percent, value } = calcValues(percentChange);

    if (
      valueDisplayRef &&
      valueDisplayRef.current &&
      valueDisplayRef.current.parentElement
    ) {
      valueDisplayRef.current.parentElement.style.backgroundColor =
        theme.palette.primary.bg;
      valueDisplayRef.current.parentElement.style.borderWidth = '0px';
    }
    valueDisplayRef.current!.innerText = valueDisplayFormatter(
      value,
      suggestedValue
    );

    filledTrackRef.current!.style.width = `${percent}%`;
    emptyTrackRef.current!.style.width = `${100 - percent}%`;
  }, []);

  const handleMouseUp = useCallback(
    (eve: MouseEvent) => {
      const percentChange =
        ((eve.screenX - dragStartPos.current!) /
          wrapperRef.current!.clientWidth) *
        100;

      const { value } = calcValues(percentChange);

      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
      valueDisplayRef.current!.style.display = '';

      onChangeRef.current(value);
      eve.preventDefault();
    },
    [handleMouseMove, calcValues]
  );

  return (
    <>
      <Wrapper ref={wrapperRef}>
        <FilledTrack
          ref={filledTrackRef}
          style={{ width: `${percentFilled.current}%` }}
        />

        {new Array(Math.floor((max - min) / stepSize) - 1)
          .fill(undefined)
          .map((_, i) => (
            <Notch
              key={i}
              style={{ left: (i + 1) * stepPercentSize.current + '%' }}
            />
          ))}

        <Thumb
          defaulted={value === suggestedValue}
          onDragStart={(eve) => {
            eve.preventDefault();
          }}
          onMouseDown={(eve) => {
            dragStartPos.current = eve.screenX;
            valueDisplayRef.current!.style.display = 'block';
            window.addEventListener('mousemove', handleMouseMove);
            window.addEventListener('mouseup', handleMouseUp);
            eve.preventDefault();
          }}
        >
          <div ref={valueDisplayRef}>
            {valueDisplayFormatter(value, suggestedValue)}
          </div>
        </Thumb>
        <EmptyTrack
          ref={emptyTrackRef}
          style={{ width: `${100 - percentFilled.current}%` }}
        />
      </Wrapper>
      <MinMaxLabels>
        <MinMaxLabel>{minLabel}</MinMaxLabel>
        <MinMaxLabel>{maxLabel}</MinMaxLabel>
      </MinMaxLabels>
    </>
  );
};

export default Range;
