import React, { FC } from 'react';
import styled from 'styled-components';

const Button = styled.button`
  width: 40px;
  height: 40px;
  margin: auto 0;
  padding: 5px;

  border: 0;
  background: transparent;

  svg {
    fill: ${({ theme }) => theme.palette.tertiary.bg};
  }
`;

interface Props {
  onClick(): void;
}

const CloseButton: FC<Props> = ({ onClick }) => {
  return (
    <Button onClick={onClick}>
      <svg
        preserveAspectRatio="xMidYMid meet"
        data-bbox="25.9 25.9 148.2 148.2"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="25.9 25.9 148.2 148.2"
        role="img"
      >
        <g>
          <path d="M171.3 158.4L113 100l58.4-58.4c3.6-3.6 3.6-9.4 0-13s-9.4-3.6-13 0L100 87 41.6 28.7c-3.6-3.6-9.4-3.6-13 0s-3.6 9.4 0 13L87 100l-58.4 58.4c-3.6 3.6-3.6 9.4 0 13s9.4 3.6 13 0L100 113l58.4 58.4c3.6 3.6 9.4 3.6 13 0s3.5-9.5-.1-13z"></path>
        </g>
      </svg>
    </Button>
  );
};

export default CloseButton;
