import React from 'react';
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import moment from 'moment';
import { Payload, TooltipWrapper } from 'components/Graph/GraphBase';
import styled, { useTheme } from 'styled-components';
import { EVData } from '.';
import { numToSpacedString } from 'components/Graph/GraphData';
import GraphLoader from 'components/Loader/graphLoader';
import { addCharToNum } from 'util/range';

interface Props {
  data: EVData[];
  isLoaded: boolean;
  maxPricePadding: number;
}
const yAxisWidth = 60;
const xHeight = 600;

const MarginWrapper = styled.div`
  margin: 0px 0;

  margin-left: ${-yAxisWidth}px;
  margin-right: ${-yAxisWidth}px;

  position: relative;
  height: ${xHeight}px;
`;

const AspectWrapOuter = styled.div`
  position: relative;
  width: 100%;
  padding-top: 20%;
`;
const AspectWrapInner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const CustomLabel = styled.span`
  color: black;
  display: inline-block;
  font-size: 16px;
  margin-left: 5px;
  margin-right: 10px;
`;
const PaddingGraph = styled.div``;

const EVGraph: React.FC<Props> = ({ data, isLoaded, maxPricePadding }) => {
  const theme = useTheme();
  if (data.length === 0) {
    return (
      <PaddingGraph>
        <MarginWrapper>
          <AspectWrapOuter>
            <AspectWrapInner>
              {isLoaded && (
                <GraphLoader
                  title={'No available data come back later '}
                  forceError={true}
                />
              )}
            </AspectWrapInner>
          </AspectWrapOuter>
        </MarginWrapper>
      </PaddingGraph>
    );
  }

  var yTicks = Math.floor(maxPricePadding / 2) + 1;
  return (
    <PaddingGraph>
      <ResponsiveContainer width="100%" height={xHeight}>
        <ComposedChart
          margin={{
            top: 20,
            right: 0,
            left: 0,
            bottom: 0,
          }}
          data={data}
        >
          <CartesianGrid stroke={theme.palette.common.lightBorder} />
          <XAxis
            textAnchor="start"
            scale="band"
            dataKey="time"
            tickFormatter={(v) => {
              const date = moment(v, 'YYYY-MM-DD hh:mm').toDate();
              return `${
                date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
              }:00`;
            }}
            tick={{
              fill: theme.palette.text.fg,
              textAnchor: 'middle',
            }}
            stroke={theme.palette.common.lightBorder}
            interval={1}
          />
          <YAxis
            domain={[0, maxPricePadding]}
            yAxisId="left"
            orientation="left"
            width={yAxisWidth}
            tick={{ fill: theme.palette.text.fg }}
            stroke={theme.palette.common.lightBorder}
            tickCount={yTicks}
          />
          <Bar
            name={'Price'}
            key={'bcPrice'}
            yAxisId="left"
            type="monotone"
            dataKey={'bcPrice'}
            stroke={'rgb(201, 224, 136)'}
            fill={'rgb(201, 224, 136)'}
          ></Bar>
          <Line
            name={`Nord Pool Price`}
            key={'price'}
            yAxisId="left"
            type="monotone"
            dataKey={`price`}
            stroke="#3e8a88"
            dot={{ fill: '#3e8a88' }}
            strokeWidth={2}
          ></Line>
          <Tooltip
            // labelFormatter={tooltipLabelFormatter}
            content={renderTooltip}
          />
          <Legend
            formatter={(value, entry, index) => (
              <CustomLabel>{value}</CustomLabel>
            )}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </PaddingGraph>
  );
};

export default EVGraph;

const tooltipLabelFormatter = (value: string) =>
  moment(value).format('yyyy-MM-DD, HH:00'); // value string should include time

const renderTooltip = (props: any) => {
  return (
    <TooltipWrapper>
      <label>{tooltipLabelFormatter(props.label)}</label>
      {props.payload.map((x: any) => {
        var valueNumber = Number(x.value);
        if (!isNaN(valueNumber)) {
          return (
            <Payload color={x.color} key={x.name}>
              <span className={'first'}>{x.name}:</span>
              <span>
                {addCharToNum(
                  (Math.round(Number(valueNumber) * 100) / 100).toFixed(2),
                  ' '
                )}
              </span>
            </Payload>
          );
        }
        return (
          <Payload color={x.color} key={x.name}>
            <span className={'first'}>{x.name}</span>
            <span>{x.value}</span>
          </Payload>
        );
      })}
    </TooltipWrapper>
  );
};
