import moment from 'moment';
import { selectUIStateRevView } from 'store/ui/selector';
import { ApplicationState } from '../definitions';

export function calculateCompareSrcDates(
  compareType: string,
  viewStartDate: string,
  viewEndDate: string
) {
  const utcStartMoment = moment.utc(viewStartDate);
  const utcEndMoment = moment.utc(viewEndDate);
  const dayCount = utcEndMoment.diff(utcStartMoment, 'days');

  if (compareType === 'regular' || compareType === 'currProp') {
    return { start: viewStartDate, end: viewEndDate, dayCount };
  } else if (compareType === 'lastYear') {
    // Alternate method: go back 364 days (unless we're on a leap-year and we've passed the leap-day? then subtract )
    // utcStartMoment.isLeapYear();

    const utcStartWeekday = utcStartMoment.isoWeekday();
    const oneYearBackMoment = utcStartMoment.clone().subtract(1, 'year');
    const oneYearBackWeekday = oneYearBackMoment.isoWeekday();

    const matchingDayAdjustment =
      ((utcStartWeekday - oneYearBackWeekday + 4) % 7) - 4;

    const oneYearBackWeekAdjustedMoment = oneYearBackMoment
      .clone()
      .add(matchingDayAdjustment, 'day');

    // const oneYearBackWeekAdjustedWeekday =
    //   oneYearBackWeekAdjustedMoment.weekday();

    return {
      start: oneYearBackWeekAdjustedMoment.format('yyyy-MM-DD'),
      end: oneYearBackWeekAdjustedMoment
        .clone()
        .add(dayCount, 'day')
        .format('yyyy-MM-DD'),
      dayCount,
    };
  } else throw new Error('unknown compare type:' + compareType); //return;
}

export function pickValidCompareViewDataSet(
  state: ApplicationState,
  bucketName: string,
  viewCompareStart: string,
  viewCompareEnd: string
) {
  const compareBucket = state.compCache.buckets[bucketName];

  if (!compareBucket) return null;

  const utcStartMoment = moment.utc(state.uiState.startDate);
  const utcEndMoment = moment.utc(state.uiState.endDate);
  const dayCount = utcEndMoment.diff(utcStartMoment, 'days');

  if (compareBucket.viewStartDate > viewCompareStart) return null;

  if (compareBucket.viewEndDate < viewCompareEnd) return null;
  if (
    !state.calcCache.cached?.zoneIdSet.every((cAZid) =>
      compareBucket.zoneIdSet.find((cOZid) => cOZid === cAZid)
    )
  )
    return null;
  return compareBucket;
}

export function selectCurrentViewCompareDataSet(state: ApplicationState) {
  const revenueView = selectUIStateRevView(state);
  //   const compareDates = calculateCompareSrcDates(
  //     revenueView,
  //     state.uiState.startDate,
  //     state.uiState.endDate
  //   );
  const compDS = pickValidCompareViewDataSet(
    state,
    revenueView,
    state.uiState.startDate,
    state.uiState.endDate
  );
  return compDS;
}
