export function* range(
  fromIncl = 0,
  toExcl = 0,
  predOrStep = (() => true) as ((key: number) => boolean) | number
) {
  const step =
    'number' === typeof predOrStep ? predOrStep : fromIncl <= toExcl ? 1 : -1;
  const pred = 'function' === typeof predOrStep ? predOrStep : () => true;
  for (
    let v = fromIncl;
    Math.abs(v - fromIncl) < Math.abs(toExcl - fromIncl);
    v += step
  ) {
    if (pred(v)) yield v;
  }
  return;
}

export function addCharToNum(nStr: string, char: string) {
  var number = nStr;
  number += '';
  var x = nStr.split('.');
  var x1 = x[0];
  var x2 = x.length > 1 ? '.' + x[1] : '';
  var rgx = /(\d+)(\d{3})/;
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, '$1' + char + '$2'); // changed comma to dot here
  }
  return x1 + x2;
}
