import { Pricing, PricingGroup, PricingRange } from 'store/zone/reducer';

export const durationSort = (duration: string) => {
  switch (duration) {
    case 'hour':
      return 1;
    case 'day':
      return 2;
    case 'week':
      return 3;
    default:
      return 4;
  }
};
export const getSort = (priceRange: PricingRange) => {
  var add = 0;
  if (!!priceRange.groupId) {
    add += 3;
  }

  return durationSort(priceRange.duration) + add;
};

export const getPricingParamName = (
  curr: PricingRange,
  group?: PricingGroup
) => {
  if (group) {
    return {
      title: group.name,
      value: `group_${curr.duration}`,
      sort: getSort(curr),
    };
  }
  return {
    title: getDurationTitle(curr.duration),
    value: curr.duration,
    sort: getSort(curr),
  };
};

export const getDurationTitle = (
  duration: string,
  segment?: string,
  group?: PricingGroup
) => {
  switch (duration) {
    case 'hour':
      return 'Hour';
    case 'day':
      if (group && group.condition) {
        if (
          group.condition.durationDayRange &&
          group.condition.durationDayRange[0] !== 0
        ) {
          return `${group.condition.durationDayRange[0]} day+`;
        }
      }
      return 'Day';
    case 'week':
      return segment === 'surcharge' ? 'After first day' : 'Week';
    default:
      return '';
  }
};

// export const dummyEvRanges = () => {
//   var range: PricingRange[] = [];
//   var evChargeDuration: PricingRange['duration'][] = ['hour', 'day', 'week'];

//   return evChargeDuration.map((f, index) => {
//     return {
//       id: (index + 10) * -1,
//       active: true,
//       duration: f,
//       segment: 'surcharge',
//       min: f == 'hour' ? 10 : f == 'day' ? 80 : 50,
//       max: f == 'hour' ? 16 : f == 'day' ? 100 : 70,
//       toggleable: true,
//     };
//   }) as PricingRange[];
// };

export const generatePriceRange = (
  row: PricingRange | undefined,
  duration: string,
  chargeData: PricingRange[]
) => {
  var _chargeData = chargeData.find((f) => f.duration === duration);

  const data = {
    range: undefined,
    chargeRange: undefined,
  } as {
    range: PricingRange | undefined;
    chargeRange?: { min: number; max: number };
  };

  if (row != undefined) {
    data.range = row!;

    if (_chargeData != undefined && _chargeData.active) {
      data.chargeRange = {
        min: _chargeData.min!,
        max: _chargeData.max!,
      };
    }
  }

  return data;
};
