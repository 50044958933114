export const RouteParam = {} as const;

const Route = {
  //Facilities: '/facilities/{:id}',
  Revenue: '/revenue',
  Demand: '/demand',
  Pricing: '/pricing',
  //JTest: '/jtest',
} as const;

export default Route;
