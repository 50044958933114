export class PriceV3Api {
  basePath: string = '/api/priceui/v3/';
  get = async (url: RequestInfo, options?: RequestInit) => {
    return await makeGet(this.basePath, url, !options ? {} : options!);
  };
  post = async (
    url: RequestInfo,
    body: string,
    options?: RequestInit,
    signal?: AbortSignal
  ) => {
    return await makePost(
      this.basePath,
      url,
      body,
      !options ? {} : options!,
      signal
    );
  };

  delete = async (url: RequestInfo, options?: RequestInit) => {
    return await makeDelete(this.basePath, url, !options ? {} : options!);
  };
  put = async (url: RequestInfo, body: string, options?: RequestInit) => {
    return await makePut(this.basePath, url, body, !options ? {} : options!);
  };
}

const makeGet = async (
  baseUrl: string,
  url: RequestInfo,
  options: RequestInit
) => {
  var result = await fetch(`${baseUrl}${url}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    ...options,
  });
  var resp = await result.text();
  var json = JSON.parse(resp) as any;
  return json;
};

const makePut = async (
  baseUrl: string,
  url: RequestInfo,
  body: string,
  options: RequestInit
) => {
  var result = await fetch(`${baseUrl}${url}`, {
    method: 'PUT',
    body,
    headers: {
      'Content-Type': 'application/json',
    },
    ...options,
  });
  var resp = await result.text();
  if (!resp) {
    return;
  }
  var json = JSON.parse(resp) as any;
  return json;
};

const makePost = async (
  baseUrl: string,
  url: RequestInfo,
  body: string,
  options: RequestInit,
  signal?: AbortSignal
) => {
  var result = await fetch(`${baseUrl}${url}`, {
    method: 'POST',
    body,
    headers: {
      'Content-Type': 'application/json',
    },
    ...options,
    signal,
  });
  if (result.status >= 400 && result.status < 600) {
    // Access and server-errors won't return valid json objects if that's expected!
    return;
  }
  var resp = await result.text();
  if (!resp) {
    return;
  }
  var json = JSON.parse(resp) as any;
  return json;
};

const makeDelete = async (
  baseUrl: string,
  url: RequestInfo,
  options: RequestInit
) => {
  var result = await fetch(`${baseUrl}${url}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
    ...options,
  });
  var resp = await result.text();
  var json = JSON.parse(resp) as any;
  return json;
};
