import moment from 'moment';
import React from 'react';
import { Bar, Cell, Line } from 'recharts';
import { GraphRevenue, GTransformer } from './defs';

import {
  findGraphLevel,
  firstToUpper,
  isSpecialDay,
  roundUpMax,
} from './GraphData';

export const revenueChangeTransformer: GTransformer = (
  data,
  zoneSelected,
  compKeys
) => {
  const maxCurrent = data.data.reduce(
    (pre, cur) =>
      Math.max(
        pre,
        cur.segments.reduce(
          (prevSegMaxValue, c) =>
            Math.max(
              prevSegMaxValue,
              (c[compKeys.newerSegValueKey] as GraphRevenue).current,
              (c[compKeys.olderSegValueKey] as GraphRevenue).current
            ),
          0
        )
      ),
    0
  );
  const maxTotal = data.data.reduce(
    (pre, cur) =>
      Math.max(
        pre,
        Math.max(
          cur[compKeys.newerAccKey] as number,
          cur[compKeys.olderAccKey] as number,
          // TODO: only handles "new" segments.. should add support for old ones as well? Or do we want to separate total and segment compares?
          cur.segments.reduce(
            (p, c) =>
              Math.max(
                p,
                (c[compKeys.olderSegValueKey] as GraphRevenue).accumulated,
                (c[compKeys.newerSegValueKey] as GraphRevenue).accumulated
              ),
            0
          )
        )
      ),
    0
  );

  const leftMaxViewValue = findGraphLevel(maxCurrent);
  const rightMaxViewValue = findGraphLevel(maxTotal);

  const chartData = (() => {
    return [
      <Bar
        key={'comRevenue'}
        yAxisId="left"
        dataKey={compKeys.olderValueKey}
        name={compKeys.olderName + ' revenue'}
        stackId="a"
        fill="#b5d8d7"
      >
        {data.data.map((ent, idx) => {
          const special = isSpecialDay(
            moment.utc(ent.odate).toDate(),
            data
          ).eventExists;

          return (
            <Cell key={ent.odate} fill={special ? '#8cbad1' : '#b5d8d7'} />
          );
        })}
      </Bar>,
      <Bar
        key={'tnRevenue'}
        yAxisId="left"
        dataKey={compKeys.newerValueKey}
        name={compKeys.newerName + ' revenue'}
        stackId="b"
        fill={'#66b2b0'}
      >
        {data.data.map((ent, idx) => {
          const special = isSpecialDay(
            moment.utc(ent.odate).toDate(),
            data
          ).eventExists;

          const color = special
            ? '#6594b2'
            : ent.isDemandPrediction && compKeys.indicateFuture
            ? '#b5d8d7'
            : '#66b2b0';

          return (
            <Cell
              key={ent.odate}
              fill={color}
              fillOpacity={ent.isDemandPrediction ? 0.8 : undefined}
              stroke={color}
            />
          );
        })}
      </Bar>,
      <Line
        key={'olderAccRev'}
        name={compKeys.olderName + ' acc revenue'}
        yAxisId="right"
        type="monotone"
        dataKey={compKeys.olderAccKey}
        stroke="#d0e4e3"
        strokeWidth={2}
        dot={{ fill: '#d0e4e3' }}
      ></Line>,
      <Line
        key={'newerAccRev'}
        name={compKeys.newerName + ' acc revenue'}
        yAxisId="right"
        type="monotone"
        dataKey={compKeys.newerAccKey}
        stroke="#3e8a88"
        strokeWidth={2}
        dot={{ fill: '#3e8a88' }}
      ></Line>,
    ];
  })();

  return { chartData, leftMaxViewValue, rightMaxViewValue };
};
