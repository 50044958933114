import React from 'react';
import styled from 'styled-components';
import AdminNavBar from '../../../components/AdminNavBar';
import UserAdminWrapper from './userAdminContext';
import UserListComponent from './userListComponent';

interface Props {}

const Wrapper = styled.div`
  margin: 0;
`;

const UserAdmin: React.FC<Props> = (props) => {
  return (
    <Wrapper>
      <AdminNavBar />
      <UserAdminWrapper>
        <UserListComponent />
      </UserAdminWrapper>
    </Wrapper>
  );
};
export default UserAdmin;
