import Main from 'components/Main';
import NavBar from 'components/NavBar';
import React, { useContext, useEffect, useState } from 'react';
import GraphLoader from 'components/Loader/graphLoader';
import Label from 'components/input/Label';
import Select from 'components/input/Select';
import styled from 'styled-components';
import useAdjustmentsData from './useAdjustmentsData';
import Button from 'components/input/Button';
import DayTableGrid from './components/DayTableGrid';
import {
  OverrideContext,
  ProductView,
  ProductsContext,
} from './TableDataProvider';
import Input from 'components/input/Input';
import { useSelector } from 'react-redux';
import { selectCurrentFacility, selectCurrentZones } from 'store/zone/selector';
import AdjustmentController, { ProductSpecifierModel } from 'api/adjustment';
import { getUser, hasEditRights } from 'store/uiSettings/selectors';
import { useRouteMatch } from 'react-router';
import { FacilityPageProps } from 'topdefinitions';
import Toggle from 'components/input/Toggle';

const FilterBar = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px 0;

  & > * {
    min-width: 150px;
  }
`;

const LeftContent = styled.div`
  display: flex;
  gap: 30px;
  align-items: center;
`;
const RightContent = styled.div`
  display: flex;
  gap: 30px;
  justify-content: flex-end;
  align-items: center;
`;

const ButtonWrapper = styled.div`
  display: flex;
  max-height: 40px;
  justify-content: flex-end;
  gap: 20px;
`;

const ToggleWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

var timer: any = null;
const Adjustments: React.FC = () => {
  const { tableData, overrides, hasOverrides, resetOverrides } =
    useAdjustmentsData();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [productOverride, setProductOverride] = useState<boolean>(false);
  const products = useContext(ProductsContext);
  const overrideContext = useContext(OverrideContext);
  const activeZones = useSelector(selectCurrentZones);
  const canEdit = useSelector(hasEditRights);
  const [visibleProducts, setVisibleProducts] = useState<
    ProductSpecifierModel[]
  >([]);
  const match = useRouteMatch<FacilityPageProps['match']['params']>();

  const ac = new AdjustmentController();

  const facilityId = match?.params?.facilityId;
  const user = useSelector(getUser);
  var facility = user.facilities.find((f) => f.route === facilityId);

  const [activeExport, setActiveExport] = useState<boolean | null>(null);

  useEffect(() => {
    const getActiveExport = async () => {
      var active = await ac.getExportStatus(facility!.id);
      setActiveExport(active);
    };
    getActiveExport();
  }, [facilityId]);

  useEffect(() => {
    var _productList = Object.keys(products.zoneProducts).reduce(
      (acc, curr) => {
        if (activeZones.find((zone) => zone.id === Number(curr))) {
          var _products = products.zoneProducts[Number(curr)]!.map(
            (product) => {
              return products.availableProducts.find(
                (f) => f.productId === product
              );
            }
          ) as ProductSpecifierModel[];
          return [...acc, ..._products.filter((f) => f !== undefined)];
        }
        return acc;
      },
      [] as ProductSpecifierModel[]
    );
    setVisibleProducts(_productList);
    if (
      !!products &&
      products.selectedProduct === '' &&
      _productList.length > 0
    ) {
      onChangeSelectedProduct(_productList[0].friendlyName);
    } else if (
      _productList.length > 0 &&
      !_productList.find((f) => f.friendlyName === products.selectedProduct)
    ) {
      onChangeSelectedProduct(_productList[0].friendlyName);
    }
  }, [products, activeZones]);

  useEffect(() => {
    if (!!products && products.selectedProduct !== '') {
      onChangeSelectedProduct(products.selectedProduct);
    }
  }, [products.los, products.nrOfDays]);

  // update setProductOverride when overrides change
  useEffect(() => {
    if (overrides) {
      setProductOverride(() => hasOverrides());
    }
  }, [
    overrides.overrides[products.selectedProduct],
    products.selectedProduct,
    overrides.overrides,
  ]);

  const onChangeSelectedProduct = async (selected: string) => {
    setIsLoading(true);
    products.setSelectedProduct(selected);
    if (selected !== '') {
      await tableData.makeGetPivotCall(selected);
    }
    setIsLoading(false);
  };

  const onResetAllOverrides = () => {
    resetOverrides();
  };

  const onExportDataClick = () => {
    console.log('Exporting data');
    setIsLoading(true);
    if (activeExport) {
      setTimeout(async () => {
        await tableData.exportDataCall(products.selectedProduct, async () => {
          resetOverrides();
          await tableData.makeGetPivotCall(products.selectedProduct);
          setIsLoading(false);
        });
      }, 0);
    } else {
      setTimeout(async () => {
        await tableData.generateDataCall(products.selectedProduct, async () => {
          resetOverrides();
          await tableData.makeGetPivotCall(products.selectedProduct);
          setIsLoading(false);
        });
      }, 0);
    }
  };

  return (
    <Main>
      <NavBar />
      <FilterBar>
        <LeftContent>
          <Label label="Price Product">
            <Select
              value={products.selectedProduct}
              onChange={(e) => {
                onChangeSelectedProduct(e.target.value);
              }}
              disabled={isLoading ? true : false}
            >
              <option value={''}></option>
              {visibleProducts?.map((product) => {
                return (
                  <option
                    value={`${product.friendlyName}`}
                    key={`${product.friendlyName}`}
                  >
                    {product.friendlyName}
                  </option>
                );
              })}
            </Select>
          </Label>
          <Label label="Number of days">
            <Input
              type="number"
              value={products.nrOfDays}
              onChange={(e) => {
                products.setNrOfDays(Number(e.target.value));
              }}
              disabled={isLoading ? true : false}
            />
          </Label>
          <Label label="Length of stay">
            <Input
              type="number"
              value={products.los}
              onChange={(e) => {
                var value = Number(e.target.value);
                products.setLos(value);
              }}
              disabled={isLoading ? true : false}
            />
          </Label>

          {!isLoading && tableData.tableData && (
            <Label label="Display">
              <Select
                value={products.productView}
                onChange={(e) => {
                  products.setProductView(e.target.value);
                }}
                disabled={isLoading ? true : false}
              >
                {Object.keys(ProductView).map((product) => {
                  return (
                    <option value={`${product}`} key={`${product}`}>
                      {ProductView[product as keyof typeof ProductView]}
                    </option>
                  );
                })}
              </Select>
            </Label>
          )}
        </LeftContent>
        <RightContent>
          {canEdit && activeExport != null && (
            <ToggleWrapper>
              <span>
                Export is <b>{!activeExport ? 'inactive' : 'active'} </b>
              </span>
              <Toggle
                checked={activeExport!}
                onChange={async () => {
                  var newToggleValue = !activeExport;
                  var toggleApproved = await ac.toggleFacilityExport(
                    facility!.id,
                    newToggleValue
                  );
                  console.log(toggleApproved);
                  if (!!toggleApproved) {
                    setActiveExport(newToggleValue);
                  }
                }}
              />
            </ToggleWrapper>
          )}
          {canEdit && (
            <ButtonWrapper>
              {!isLoading && productOverride && (
                <Button onClick={() => onResetAllOverrides()}>Reset</Button>
              )}
              {!isLoading && tableData.tableData && (
                <Button onClick={() => onExportDataClick()}>
                  {activeExport ? 'Export' : 'Generate'}
                </Button>
              )}
            </ButtonWrapper>
          )}
        </RightContent>
      </FilterBar>

      {isLoading ? (
        <GraphLoader></GraphLoader>
      ) : (
        tableData.tableData && <DayTableGrid data={tableData.tableData} />
      )}
    </Main>
  );
};

export default Adjustments;
