import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '../../components/Modal';
import { eventStoreRemoveEvent } from '../../store/event/action';
import { ApplicationState } from '../../store/definitions';
import Label from '../../components/input/Label';
import { PriceV3Api } from 'constants/api-fetch';
import { selectCurrentFacility } from 'store/zone/selector';

const RemEventModal: FC<{ id: number }> = ({ id }) => {
  const dispatch = useDispatch();
  const fac = useSelector(selectCurrentFacility);
  const event = useSelector(
    (root: ApplicationState) =>
      root.eventData[fac.id].find((te) => te.dateID === id)!
  );

  return (
    <Modal
      title="Really remove?"
      buttons={[
        {
          label: 'Remove',
          onClick: () => {
            var api = new PriceV3Api();
            api.delete(`remove/event/${fac.id}/${id}`).then((res: boolean) => {
              if (res) {
                dispatch(eventStoreRemoveEvent(fac.id, id));
              }
            });
          },
        },
      ]}
    >
      <Label label="Remove:">{event.name}</Label>
    </Modal>
  );
};

export default RemEventModal;
