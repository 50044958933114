import Select from 'components/input/Select';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import AdminNavBar from '../../../components/AdminNavBar';
import { makeApiCall } from '../Users/userAdminContext';

const Wrapper = styled.div`
  margin: 0;
`;

interface HolidayRoute {
  id: number;
  name: string;
  events?: {
    id: number;
    name: string;
    dates: { id: number; fullDay: boolean; start: string; end: string }[];
  }[];
}

const EventWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 4fr;
  grid-auto-flow: row;
  grid-auto-rows: 0fr;
  grid-row-gap: 1vw;
  grid-column-gap: 1vw;
`;

const DateWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-auto-flow: row;
  grid-auto-rows: 0fr;
  grid-row-gap: 1vw;
  grid-column-gap: 1vw;
`;

const DateAdmin: React.FC<{}> = (props) => {
  const [routes, setRoutes] = useState([] as HolidayRoute[]);
  const [routeId, setRouteId] = useState('' as string);
  const [route, setRoute] = useState(null as HolidayRoute | null);

  useEffect(() => {
    console.log('Load routes');
    makeApiCall('/dateadmin/holidayroutes', { method: 'GET' })
      .then((v) => {
        console.log('Got routes:');
        setRoutes(v.routes);
      })
      .catch((e) => {});
    return () => {};
  }, []);

  useEffect(() => {
    if (routeId === '') {
      console.log('Picked NO route!');
      setRoute(null);
      return;
    }
    makeApiCall('/dateadmin/holidayroutes/' + routeId, { method: 'GET' })
      .then((v) => {
        console.log('Loaded route!', v);
        setRoute(v);
      })
      .catch((e) => {});
  }, [routeId]);

  return (
    <Wrapper>
      <AdminNavBar />

      <Select
        style={{ marginTop: '20px' }}
        value={routeId}
        onChange={(e) => setRouteId(e.target.value)}
      >
        <option value="">None</option>
        {routes.map((r) => (
          <option key={r.id} value={r.id}>
            {r.name}
          </option>
        ))}
      </Select>
      <hr />
      <EventWrapper>
        {!route
          ? null
          : route.events?.map((e) => (
              <>
                <div>{e.name}</div>
                <DateWrapper>
                  {e.dates
                    .slice()
                    .sort(
                      (a, b) =>
                        new Date(a.start).valueOf() -
                        new Date(b.start).valueOf()
                    )
                    .map((d) => (
                      <div>{moment.utc(d.start).format('YYYY-MM-DD')}</div>
                    ))}
                </DateWrapper>
              </>
            ))}
      </EventWrapper>
    </Wrapper>
  );
};

export default DateAdmin;
