import { EVENTSTOREACTIONS } from './constants';
import { Event } from './definitions';

export const eventStoreAddEvent = (
  routeId: number,
  //zoneId: number | null,
  event: Event
) => ({
  type: EVENTSTOREACTIONS.EVENT_ADD,
  routeId,
  //zoneId,
  event,
});

export const eventStoreModEvent = (
  routeId: number,
  event: Partial<Event> // & { id: number }
) => ({
  type: EVENTSTOREACTIONS.EVENT_APPLY,
  routeId,
  event,
});

export const eventStoreRemoveEvent = (routeId: number, dateId: number) => ({
  type: EVENTSTOREACTIONS.EVENT_REMOVE,
  routeId,
  dateId,
});
