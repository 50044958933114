import { UIState } from './reducer';
export const EmptyGUID = '00000000-0000-0000-0000-000000000000';
export const durationToMomentDuration = (duration: UIState['duration']) => {
  switch (duration) {
    case 'day':
      return 'day';
    case 'week':
      return 'week';
    case 'month':
      return 'month';
    case 'monthsInYear':
    case 'weeksInYear':
      return 'year';
  }
};

export const convertToFirstUpperCase = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const getFormattedDate = (date: Date): string => {
  const month = date.toLocaleString('default', { month: 'short' });
  const day = date.getDate();
  return `${day} ${month}`;
};

export const getFormattedDateWithYear = (date: Date): string => {
  const month = date.toLocaleString('default', { month: 'short' });
  const day = date.getDate();
  return `${day} ${month} ${date.getFullYear()}`;
};

export const getSpecialZonePickerValues = (facilityId: string) => {
  return (FacilitySpecial[facilityId] ??
    facilitySpecialDefaultZonePickerObject) as FacilitySpecialZonePicker[];
};

const facilitySpecialDefaultZonePickerObject: FacilitySpecialZonePicker[] = [];
// {
//   name: 'Very special zones',
//   id: 'veryspecial',
//   zoneIds: ['00000000-0000-0000-0000-000000000000'],
// },

const FacilitySpecial = {
  arlanda: [
    {
      name: 'Express zones',
      id: 'express',
      zoneIds: [
        '168a2656-89d9-4e78-91a4-e0c147772b72',
        '02ca5efb-5a27-42ce-9942-d3ed88e026a4',
        'a18be299-90ca-44e5-b863-d832f7335327',
        '2b261269-9c9d-4557-83af-888647f88eb2',
        '1bf400ce-7070-48fc-a52e-4e7a8d2aff02',
        'df801d2d-e6f7-4a59-aae0-a186e4d82cdd',
        '04b9441c-faa8-446b-9144-bc4be08a9287',
        'cb19c39a-aa40-448c-89f6-cce27d233899',
        '383f622a-3fb8-4cff-95c2-c57370e5c093',
        '27e085be-9b74-49bb-9a6e-0e66057aa2ba',
      ],
    },
    {
      name: 'Long term zones',
      id: 'longterm',
      zoneIds: [
        'c7dc4f35-7f28-41ef-acf5-0720446288ff',
        '0fde1ee6-7c9a-49e9-baa7-4a237456e7df',
      ],
    },
  ],
  landvetter: [
    {
      name: 'Express zones',
      id: 'express',
      zoneIds: [
        '8cab04e1-76cb-47fa-b847-03352162727a',
        'a5deac9e-927b-453c-afee-a0603419f5ee',
        '0abb6f53-8069-4b9d-b74b-b37bc8b3f93d',
      ],
    },
    {
      name: 'Long term zones',
      id: 'longterm',
      zoneIds: [
        'a126b961-95cf-4b69-bf24-6da1f1070bed',
        '2e5a2c3d-c094-4fa2-b52b-59c2a9bed7bd',
        'ed2ff6c2-1f82-4598-a2dd-e08dc63795de',
        '5c07d53b-372b-425f-bf1a-c24f7537fa73',
      ],
    },
  ],
} as { [key: string]: FacilitySpecialZonePicker[] };

export interface FacilitySpecialZonePicker {
  name: string;
  id: string;
  zoneIds: string[];
}

export enum COMPARE_TYPE {
  PERIOD,
  DAY_TO_DAY,
}

export const darkenColor = (hexColor: string, index: number) => {
  function clamp(value: number) {
    return Math.max(0, Math.min(255, value));
  }

  function hexToRgb(hex: string) {
    return [
      parseInt(hex.slice(1, 3), 16),
      parseInt(hex.slice(3, 5), 16),
      parseInt(hex.slice(5, 7), 16),
    ];
  }

  function rgbToHex(r: number, g: number, b: number) {
    return `#${r.toString(16).padStart(2, '0')}${g
      .toString(16)
      .padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
  }

  const rgb = hexToRgb(hexColor);
  var i = 1 * index;
  rgb[0] = clamp(rgb[0] - i * 25);
  rgb[1] = clamp(rgb[1] - i * 25);
  rgb[2] = clamp(rgb[2] - i * 25);
  return rgbToHex(rgb[0], rgb[1], rgb[2]);
};
