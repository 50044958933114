import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '../../components/Modal';
import { zoneStoreRemPrice } from '../../store/zone/action';
import { ApplicationState } from '../../store/definitions';
import Label from '../../components/input/Label';
import { PriceV3Api } from 'constants/api-fetch';

const RemPriceProductModal: FC<{ zoneId: number; priceId: number }> = ({
  zoneId,
  priceId,
}) => {
  const dispatch = useDispatch();
  const price = useSelector(
    (root: ApplicationState) =>
      root.zoneData
        .find((z) => z.id === zoneId)!
        .pricings.find((pr) => pr.id === priceId)!
  );

  return (
    <Modal
      title="Really remove?"
      buttons={[
        {
          label: 'Remove',
          onClick: () => {
            var api = new PriceV3Api();
            api.delete(`remove/pricing/${priceId}`).then((success: boolean) => {
              if (success) {
                dispatch(zoneStoreRemPrice(zoneId, priceId));
              }
            });
          },
        },
      ]}
    >
      <Label label="Remove:">{price.name}</Label>
    </Modal>
  );
};

export default RemPriceProductModal;
