import Label from 'components/input/Label';
import Select from 'components/input/Select';
import React from 'react';
import { Pricing, Zone } from 'store/zone/reducer';
import styled from 'styled-components';

const segmentInputClass = 'segmentInput';
const periodInputClass = 'periodInput';
const pricesInputClass = 'pricesInput';

const fromDateClass = 'fromDate';
const toDateClass = 'toDate';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns:
    minmax(190px, 1fr) minmax(190px, 1fr) minmax(190px, 1fr)
    3fr 1fr 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 20px;
  margin: 20px 0;
  flex: 1;

  & > * {
    min-width: 140px;
  }

  .${segmentInputClass} {
    grid-area: 1/1/2/2;
  }
  .${pricesInputClass} {
    grid-area: 1/3/2/4;
  }
  .${periodInputClass} {
    grid-area: 1/2/2/3;
  }

  .${fromDateClass} {
    grid-area: 1/5/2/6;
  }
  .${toDateClass} {
    grid-area: 1/6/2/7;
  }
`;
interface Props {
  zones: Zone[];
  onChange: (zone: Zone) => void;
  selectedZone?: number;
  selectedPrice?: number;
  onPricingChange: (price: Pricing) => void;
  pricings: Pricing[];
}

const EVGraphParams: React.FC<Props> = ({
  onChange,
  zones,
  selectedZone,
  selectedPrice,
  pricings,
  onPricingChange,
}) => {
  return (
    <Wrapper>
      <Label label="EV Zones" className={segmentInputClass}>
        <Select
          disabled={zones.length === 0}
          value={selectedZone}
          onChange={(evt) => {
            var id = parseInt(evt.target.value);
            onChange(zones.find((f) => f.id === id)!);
          }}
        >
          {zones.length === 0 && <option>No available EV zones</option>}
          {zones.length > 0 && <option>No zone selected</option>}
          {zones.map((x) => (
            <option value={x.id}>{x.name}</option>
          ))}
        </Select>
      </Label>
      {!!selectedZone && (
        <Label label="Zone pricing" className={periodInputClass}>
          <Select
            onChange={(evt) => {
              var id = parseInt(evt.target.value);
              onPricingChange(pricings.find((f) => f.id === id)!);
            }}
            value={selectedPrice}
          >
            {pricings.map((f) => (
              <option value={f.id}>{f.name}</option>
            ))}
          </Select>
        </Label>
      )}
    </Wrapper>
  );
};

export default EVGraphParams;
