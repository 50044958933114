import * as Actions from './action';
import { ActionReturns } from '../../util/tshelpers';
//import { CCSTORE } from './constants';
import {
  CompareDataSet,
  CompareDataSetDescBase,
  CompareDataSetDescription,
  CompCState,
} from './definitions';
import { COMPARECACHEACTIONS } from './constants';
type Actions = ActionReturns<typeof Actions>;

const initialState = { buckets: {}, pending: {} } as CompCState;

const makeDesc = (action: Actions): CompareDataSetDescription => {
  switch (action.type) {
    case COMPARECACHEACTIONS.COMPARECACHE_PUSHPENDING:
    case COMPARECACHEACTIONS.COMPARECACHE_PUSHDATA:
      return Actions.pushCompareCachePending(
        action.compareType,
        action.zoneIdSet,
        action.viewStartDate,
        action.viewEndDate,
        action.srcStartDate,
        action.srcEndDate
      ) as CompareDataSetDescription;
    // return {
    //   zoneIdSet: action.zoneIdSet,
    //   viewStartDate: action.viewStartDate,
    //   viewEndDate: action.viewEndDate,
    //   srcStartDate: action.srcStartDate,
    //   srcEndDate: action.srcEndDate,
    //   idkey: (action as any).idkey,
    // } as CompareDataSetDescBase;
    default:
      return 0 as never;
  }
};

export const reducer = (state = initialState, action: Actions) => {
  switch (action.type) {
    case COMPARECACHEACTIONS.COMPARECACHE_PUSHPENDING: {
      return {
        ...state,
        pending: {
          ...state.pending,
          [action.compareType]: makeDesc(action),
        },
      } as CompCState;
    }
    case COMPARECACHEACTIONS.COMPARECACHE_PUSHDATA: {
      const desc = makeDesc(action)!;
      const pending = state.pending[action.compareType];
      let removePending = pending && pending.idkey === desc.idkey;
      const nextPending = !removePending ? state.pending : { ...state.pending };
      if (removePending) delete state.pending[action.compareType];
      return {
        ...state,
        pending: nextPending,
        buckets: {
          ...state.buckets,
          [action.compareType]: {
            ...desc,
            dataByZone: action.dataByZone,
          },
        },
      } as CompCState;
    }
    default:
      return state;
  }
};
