import React, { useEffect, useState } from 'react';

//Using generic line charts component to display the data
import LineChart, {
  DataPoint,
  GraphTop,
  LeftContent,
  RightContent,
} from 'components/Graph/GenericLineCharts';

import {
  PerformanceDataResponse,
  GraphController,
  PerformanceResponse,
  PerformanceEntityResponse,
} from 'api/graph';
import Main from 'components/Main';
import styled from 'styled-components';
import Label from 'components/input/Label';
import PTDatePicker from 'components/DateRangePicker/DatePicker';
import moment from 'moment';
import {
  selectAvailableFacilityZones,
  selectCurrentZones,
} from 'store/zone/selector';
import { useSelector } from 'react-redux';
import Select from 'components/input/Select';
import Loader from 'components/Loader';
import { getDaysBetweenDates } from 'util/date';
import Sticker from 'components/Sticker';
import { GraphData } from 'components/Graph/defs';
import { Payload } from 'components/Graph/GraphBase';
import {
  firstToUpper,
  formatNumberWithSpaces,
} from 'components/Graph/GraphData';
import ExportButton from 'util/excel';

const PerformanceGraph: React.FC = () => {
  var initialStartDate = moment().subtract(28, 'days').subtract('7', 'days');
  var graphController = new GraphController();
  const zones = useSelector(selectAvailableFacilityZones);
  const [data, setData] = React.useState<PerformanceResponse>();
  const [startDate, setStartDate] =
    React.useState<moment.Moment>(initialStartDate);
  const [segments, setSegments] = useState<number[]>([100, 101]);
  const [endDate, setEndDate] = React.useState<moment.Moment>(
    moment(initialStartDate).add(28, 'days')
  );

  const activeZones = useSelector(selectCurrentZones);
  const [zoneIds, setZoneIds] = React.useState<number[]>([]);
  const [controller, setController] = useState<AbortController>();
  const [dataSource, setDataSource] = React.useState<string>('occupancy');

  const [fetching, isFetching] = useState<boolean>(false);

  useEffect(() => {
    setZoneIds(activeZones.map((f) => f.id));
  }, [activeZones]);

  useEffect(() => {
    if (zoneIds.length !== 0) {
      isFetching(true);
      if (!!controller) {
        try {
          controller.abort();
        } catch (e) {}
      }
      fetchData();
    }
  }, [startDate, zoneIds, endDate]);

  const fetchData = async () => {
    let controller = new AbortController();
    setController(controller);

    const { signal } = controller;
    var result = await graphController.getPredictionPerformance(
      {
        ZoneIds: zoneIds,
        StartDate: startDate.format('YYYY-MM-DD'),
        EndDate: moment(endDate).add(1, 'day').format('YYYY-MM-DD'),
        Segments: [100, 101],
        CapacityDate: startDate.format('YYYY-MM-DD'),
        CapacityEndDate: moment(endDate).add(1, 'day').format('YYYY-MM-DD'),
      },
      signal
    );

    if (!!result) {
      setController(undefined);
      setData(result);
      isFetching(false);
    }
  };

  var graphData: DataPoint[] = [];
  var segment: keyof PerformanceDataResponse =
    segments.length === 2
      ? 'total'
      : segments[0] === 100
      ? 'driveup'
      : 'booking';

  var accumelated: keyof PerformanceDataResponse =
    segments.length === 2
      ? 'accTotal'
      : segments[0] === 100
      ? 'accDriveup'
      : 'accBooking';

  var source = dataSource as keyof PerformanceEntityResponse;
  let dataPoints: DataPoint[] = [];
  if (!!data && !fetching) {
    var maxIndex = endDate.diff(startDate, 'days');

    dataPoints = Object.keys(data.forecast[source][segment]).reduce(
      (acc, curr, index) => {
        if (index > maxIndex) return acc;
        var _curr = Number(
          curr
        ) as keyof (typeof data.forecast)[typeof source][typeof segment];
        if (_curr >= 0) {
          acc.push({
            LOS: curr,
            Prediction: Number(data.forecast[source][segment][_curr]).toFixed(
              2
            ),
          });
        }
        return acc;
      },
      dataPoints as DataPoint[]
    );

    dataPoints = Object.keys(data.analysis[source][segment]).reduce(
      (acc, curr, index) => {
        if (index > maxIndex) return acc;
        var _curr = Number(
          curr
        ) as keyof (typeof data.analysis)[typeof source][typeof segment];
        if (_curr >= 0) {
          var point = dataPoints.find((f) => f.LOS === curr);
          if (!!point) {
            point.Analysis = Number(
              data.analysis[source][segment][_curr]
            ).toFixed(2);
          } else {
            acc.push({
              LOS: curr,
              Analysis: Number(data.analysis[source][segment][_curr]).toFixed(
                2
              ),
            });
          }
        }
        return acc;
      },
      dataPoints as DataPoint[]
    );

    if (source === 'occupancy') {
      dataPoints = Object.keys(data.capacity[segment]).reduce(
        (acc, curr, index) => {
          if (index > maxIndex) return acc;
          var _curr = Number(
            curr
          ) as keyof (typeof data.capacity)[typeof segment];
          if (_curr >= 0) {
            var point = dataPoints.find((f) => f.LOS === curr);
            if (!!point) {
              point.Capacity = Number(data.capacity[segment][_curr]).toFixed(2);
            } else {
              acc.push({
                LOS: curr,
                Capacity: Number(data.capacity[segment][_curr]).toFixed(2),
              });
            }
          }
          return acc;
        },
        dataPoints as DataPoint[]
      );
    }
  }
  graphData = dataPoints;

  return (
    <Main>
      <GraphTop>
        <LeftContent>
          <Label label={'Data'}>
            <Select
              onChange={(ev) => {
                var value = ev.target.value;
                setDataSource(value);
              }}
              value={dataSource}
            >
              <option value={'occupancy'}>Occupancy</option>
              <option value={'revenue'}>Revenue</option>
            </Select>
          </Label>
          <Label label={'Segment'}>
            <Select
              onChange={(eve) => {
                var value = eve.target.value;
                if (value === 'null') {
                  setSegments([100, 101]);
                } else {
                  setSegments([Number(value)]);
                }
              }}
              value={segments.length != 1 ? 'null' : segments[0]}
            >
              <option value={'null'}>Total</option>
              <option value={101}>Booking</option>
              <option value={100}>Driveup</option>
            </Select>
          </Label>
          <Label
            label="Reference from date"
            labelStyle={{ marginLeft: '40px' }}
          >
            <PTDatePicker
              dateFormat="yyyy-MM-dd"
              onChange={(date: Date) => {
                console.log(date);
                var _date = moment(date);

                var daysBetween = getDaysBetweenDates(
                  startDate.toDate(),
                  endDate.toDate()
                );

                setStartDate(moment(_date));
                setEndDate(moment(_date).add(daysBetween, 'days'));
              }}
              selected={startDate.toDate()}
              dropdownMode="select"
              selectsStart
              showMonthDropdown
              showYearDropdown
              maxDate={moment().toDate()}
            />
          </Label>
          <Label label="Reference to date">
            <PTDatePicker
              dateFormat="yyyy-MM-dd"
              onChange={(date: Date) => {
                setEndDate(moment(date));
              }}
              selected={endDate.toDate()}
              dropdownMode="select"
              selectsStart
              showMonthDropdown
              showYearDropdown
              minDate={startDate.toDate()}
            />
          </Label>
        </LeftContent>
        <RightContent>
          {!!data && (
            <>
              <Sticker
                title={`${firstToUpper(dataSource)} forecast`}
                diffValue={
                  Number(data!.analysis[source][accumelated].toFixed(0)) ?? 0
                }
                value={
                  Number(data!.forecast[source][accumelated].toFixed(0)) ?? 0
                }
                showDiff={true}
              />
              <Sticker
                title={`${firstToUpper(dataSource)} actual`}
                value={
                  Number(data!.analysis[source][accumelated].toFixed(0)) ?? 0
                }
              />
            </>
          )}
          <ExportButton
            name={`Performance`}
            data={{
              data: graphData.map((f) => {
                return {
                  date: moment(startDate)
                    .add(Number(f.LOS), 'days')
                    .format('YYYY-MM-DD'),
                  ...f,
                };
              }, {}),
              extra: {
                analysis: data?.analysis[source][accumelated],
                forecast: data?.forecast[source][accumelated],
                startDate: moment(startDate).format('YYYY-MM-DD'),
                endDate: moment(endDate).format('YYYY-MM-DD'),
                segment: segments
                  .reduce((acc, curr) => {
                    if (curr === 100) {
                      acc.push('Driveup');
                    } else if (curr === 101) {
                      acc.push('Booking');
                    }
                    return acc;
                  }, [] as string[])
                  .join(', '),
                dataSource: dataSource,
                ...zoneIds.map((f) => {
                  var zone = zones.find((x) => x.id === f);
                  return zone!.name;
                }),
              },
            }}
          />
        </RightContent>
      </GraphTop>
      <div style={{ position: 'relative' }}>
        <LineChart
          data={graphData}
          xTickFormatter={(value: any) => {
            var days = Number(value);
            return moment(startDate).add(days, 'days').format('DD/MM');
          }}
          xTicks={endDate.diff(startDate, 'days')}
          tooltip={{
            Prediction: {
              title: `${firstToUpper(dataSource)} forecast`,
              color: 'green',
              formatter: (value: any) => {
                return `${formatNumberWithSpaces(Number(value))} ${
                  dataSource === 'revenue' ? 'kr' : 'parkings'
                }`;
              },
            },
            Analysis: {
              title: `${firstToUpper(dataSource)} analysis`,
              color: 'red',
              formatter: (value: any) => {
                return `${formatNumberWithSpaces(Number(value))} ${
                  dataSource === 'revenue' ? 'kr' : 'parkings'
                }`;
              },
            },
            Capacity: {
              title: 'Capacity',
              color: 'black',
              formatter: (value: any) => {
                return `${formatNumberWithSpaces(Number(value))}`;
              },
            },
            LOS: {
              title: 'Date',
              color: 'black',
              formatter: (value: any) => {
                return [
                  `${value} days`,

                  <Payload color={'black'}>
                    Date:{' '}
                    {moment(startDate).add(value, 'days').format('YYYY-MM-DD')}
                  </Payload>,
                ];
              },
            },
          }}
          lines={[
            {
              key: 'Prediction',
              name: `Forecast ${dataSource}`,
              color: 'green',
            },
            {
              key: 'Analysis',
              name: `Analysis ${dataSource}`,
              color: 'red',
            },
            {
              key: 'Capacity',
              name: 'Capacity',
              color: 'black',
            },
          ]}
          xDataKey="LOS"
        />
        {(fetching ||
          !data ||
          Object.keys(data?.forecast[source].total).length === 0) && (
          <div
            style={{
              position: 'absolute',
              left: 0,
              top: 0,
              right: 0,
              bottom: 0,
              backgroundColor: '#ffffff',
              opacity: 0.35,
            }}
          >
            <Loader
              title={'Could not find data'}
              forceError={!!data && !fetching}
            />
          </div>
        )}
      </div>
    </Main>
  );
};

export default PerformanceGraph;
