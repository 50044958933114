import * as Actions from './action';
import { EVENTSTOREACTIONS } from './constants';
import { ActionReturns } from '../../util/tshelpers';
import moment from 'moment';
import { Events } from './definitions';

const initialState: Events = [
  // {
  //   id: 1,
  //   parent: 23,
  //   active: true,
  //   demand: -53,
  //   startDate: '2021-01-21',
  //   endDate: '2021-01-22',
  //   fullDays: true,
  //   kind: 'Maintenance',
  //   name: 'Maintwindow1',
  //   timeOfDay: 'noon',
  //   isCustom: true,
  //   routeId: 0,
  // },
  // {
  //   parent: null,
  //   id: 2,
  //   active: true,
  //   fullDays: true,
  //   startDate: '2021-01-19T23:00:00.000Z',
  //   endDate: '2021-01-20T23:00:00.000Z',
  //   demand: 50,
  //   kind: 'Concert',
  //   name: 'Boo Fighters',
  //   timeOfDay: 'fullDay',
  //   isCustom: true,
  //   routeId: 0,
  // },
];

type Actions = ActionReturns<typeof Actions>;

export function reducer(state = initialState, action: Actions) {
  /*

    case ZONESTORE.EVENT_ADD:
      return state.map((zn) =>
        zn.id === action.zoneId
          ? { ...zn, events: [...zn.events, action.event] }
          : zn
      );
    case ZONESTORE.EVENT_APPLY:
      return state.map((zn) => {
        if (!zn.events.find((ev) => ev.id === action.event.id)) return zn;
        return {
          ...zn,
          events: zn.events.map((ev) =>
            ev.id === action.event.id ? { ...ev, ...action.event } : ev
          ),
        };
      });

*/
  switch (action.type) {
    case EVENTSTOREACTIONS.EVENT_ADD:
      return {
        ...state,
        [action.routeId]: [...state[action.routeId], action.event],
      };
    case EVENTSTOREACTIONS.EVENT_APPLY:
      return {
        ...state,
        [action.routeId]: state[action.routeId].map((ev) =>
          ev.dateID === action.event.dateID &&
          ev.routeID == action.event.routeID
            ? { ...ev, ...action.event }
            : ev
        ),
      };

    case EVENTSTOREACTIONS.EVENT_REMOVE:
      return {
        ...state,
        [action.routeId]: state[action.routeId].filter(
          (ev) => ev.dateID !== action.dateId || ev.routeID !== action.routeId
        ),
      };
  }

  return state;
}
