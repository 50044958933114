import { Facility, FacilityFlag } from 'store/uiSettings/reducer';
import { Zone, ZoneFlag } from 'store/zone/reducer';

export function hasFlag(
  obj?: Zone | Facility,
  flag?: ZoneFlag | FacilityFlag
): boolean {
  if (!obj) return false;
  if ((obj as Zone).zoneFlags) {
    return !!(obj as Zone).zoneFlags!.find((f) => f === flag);
  } else {
    return !!(obj as Facility).facilityFlags!.find((f) => f === flag);
  }
}
