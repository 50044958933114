import styled from 'styled-components';
import trash from 'images/trash.svg';

const DeleteButton = styled.button`
  margin: 0;
  padding: 0;
  width: 16px;
  height: 16px;

  border: 0;
  background: transparent;
  background: url('${trash}');
  background-position: center;
  background-repeat: no-repeat;
`;

export default DeleteButton;
