import React, { useEffect } from 'react';

//Using generic line charts component to display the data
import LineChart, { DataPoint } from 'components/Graph/GenericLineCharts';

import { BookingPatternResponse, GraphController } from 'api/graph';
import Main from 'components/Main';
import styled from 'styled-components';
import Label from 'components/input/Label';
import PTDatePicker from 'components/DateRangePicker/DatePicker';
import moment from 'moment';
import {
  selectAvailableFacilityZones,
  selectCurrentZones,
} from 'store/zone/selector';
import { useSelector } from 'react-redux';
import Loader from 'components/Loader';
import { Payload } from 'components/Graph/GraphBase';
import { getDaysBetweenDates, getSameDayLastYear } from 'util/date';
import Sticker from 'components/Sticker';
import ExportButton from 'util/excel';
const GraphTop = styled.div`
  display: flex;
  flex-direction: row;
  gap: 40px;
  padding: 20px 0;
  justify-content: space-between;
  align-items: flex-start;
`;

const LeftContent = styled.div`
  display: flex;
  gap: 20px;
`;
const RightContent = styled.div`
  display: flex;
  gap: 40px;
`;

enum PRESENT_MODE {
  PAST,
  FUTURE,
}
type PresentModeDataType = {
  [key in PRESENT_MODE]: { title: string };
};

const PresentModeData: PresentModeDataType = {
  [PRESENT_MODE.PAST]: {
    title: 'Past',
  },
  [PRESENT_MODE.FUTURE]: {
    title: 'Future',
  },
};

interface CombinedData {
  CurrentLines: {
    key: string;
    name: string;
    color: string;
    zoneName?: string;
  }[];
  PreviousLines: {
    key: string;
    name: string;
    color: string;
    zoneName?: string;
  }[];
  Data: DataPoint[];
}

function getColorHash(str: string) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + (hash << 5) - hash;
  }

  const adjustedHash = (hash & 0x00ffffff).toString(16).toUpperCase();
  const adjustedColor =
    '#' + '00000'.substring(0, 6 - adjustedHash.length) + adjustedHash;

  // Convert hex color to RGB components
  const red = parseInt(adjustedColor.slice(1, 3), 16);
  const green = parseInt(adjustedColor.slice(3, 5), 16);
  const blue = parseInt(adjustedColor.slice(5, 7), 16);

  // Check if the color is light
  const isLight = red * 0.299 + green * 0.587 + blue * 0.114 > 186;

  // If the color is light, make adjustments
  if (isLight) {
    const factor = 0.7; // Adjust this factor to control darkness
    const adjustedRed = Math.floor(red * factor);
    const adjustedGreen = Math.floor(green * factor);
    const adjustedBlue = Math.floor(blue * factor);
    return `rgb(${adjustedRed}, ${adjustedGreen}, ${adjustedBlue})`;
  }

  return adjustedColor;
}

const NewBookings: React.FC = () => {
  var initialStartDate = moment().add(-1, 'days');
  const zones = useSelector(selectAvailableFacilityZones);
  var graphController = new GraphController();
  const [current, setCurrent] = React.useState<BookingPatternResponse>();
  const [previous, setPrevious] = React.useState<BookingPatternResponse>();
  const [refStartDate, setRefStartDate] = React.useState<moment.Moment>(
    moment().add(-1, 'day')
  );
  const [refEndDate, setRefEndDate] = React.useState<moment.Moment>(
    moment().add(-1, 'day')
  );
  const [startDate, setStartDate] =
    React.useState<moment.Moment>(initialStartDate);
  const [endDate, setEndDate] = React.useState<moment.Moment>(
    moment(initialStartDate).add(4, 'weeks')
  );
  var _compareDate = getSameDayLastYear(moment().add(-1, 'days'));
  const [compareDate, setCompareDate] =
    React.useState<moment.Moment>(_compareDate);
  const activeZones = useSelector(selectCurrentZones);
  const [zoneIds, setZoneIds] = React.useState<number[]>([]);

  useEffect(() => {
    setZoneIds(activeZones.map((f) => f.id));
  }, [activeZones]);

  useEffect(() => {
    if (zoneIds.length > 0) {
      fetchData(refStartDate, refEndDate, (result) => {
        setCurrent(result);
      });
    }
  }, [refStartDate, startDate, zoneIds, refEndDate, endDate]);

  useEffect(() => {
    if (zoneIds.length > 0) {
      var daysBetween = refEndDate.diff(refStartDate, 'days');
      var compareEndDate = moment(compareDate).add(daysBetween, 'days');

      fetchData(compareDate, compareEndDate, (result) => {
        setPrevious(result);
      });
    }
  }, [compareDate, zoneIds, refEndDate, startDate, endDate]);

  const fetchData = async (
    date: moment.Moment,
    _endDate: moment.Moment,
    callback: (result: BookingPatternResponse) => void
  ) => {
    var minSpan = startDate
      .startOf('day')
      .diff(refStartDate.startOf('day'), 'days');

    var maxSpan =
      minSpan + endDate.startOf('day').diff(startDate.startOf('day'), 'days');
    var daysAhead = {
      min: minSpan,
      max: maxSpan,
    };
    var result = await graphController.getBookingPeriod({
      StartDate: date.format('YYYY-MM-DD'),
      EndDate: _endDate.format('YYYY-MM-DD'),
      VisualSpan: daysAhead,
      ZoneIds: zoneIds,
    });
    callback(result);
  };

  var combinedData = {
    CurrentLines: [],
    PreviousLines: [],
    Data: [] as DataPoint[],
  } as CombinedData;

  if (!!current) {
    Object.keys(current.data)
      .filter((f) => f == '0')
      .forEach((zoneId) => {
        var data = current!.data[zoneId];
        var zone = activeZones.find((f) => f.id === Number(zoneId));
        if (!!zone || zoneId == '0') {
          Object.keys(data).forEach((curr) => {
            var key = zoneId == '0' ? 'Current' : `${zone!.name}-Current`;
            var graphPoint = combinedData.Data.find((x) => x.LOS === curr);
            if (graphPoint) {
              graphPoint[key] = data[curr];
            } else {
              var _data = {
                LOS: curr,
                [key]: data[curr],
              } as DataPoint;

              combinedData.Data.push(_data as DataPoint);
            }
            if (!combinedData.CurrentLines.find((line) => line.key === key)) {
              combinedData.CurrentLines.push({
                key: key,
                name:
                  zoneId == '0' ? 'New bookings' : `${zone!.name} new bookings`,
                color: zoneId == '0' ? 'green' : getColorHash(zone!.name),
                zoneName: zone?.name,
              });
            }
          });
        }
      });
  }

  if (!!previous) {
    Object.keys(previous.data)
      .filter((f) => f == '0')
      .forEach((zoneId) => {
        var prevData = previous!.data[zoneId];
        var zone = activeZones.find((f) => f.id === Number(zoneId));
        if (!!zone || zoneId == '0') {
          Object.keys(prevData).forEach((curr, index) => {
            var graphPoint = combinedData.Data.find((x) => x.LOS === curr);
            var key =
              zoneId == '0' ? 'Previous' : (`${zone!.name}-Previous` as string);

            if (!graphPoint) {
              combinedData.Data.push({
                Previous: prevData[curr],
                LOS: curr,
                [key]: prevData[curr],
              });
            } else {
              graphPoint[key] = prevData[curr];
            }
            if (!combinedData.PreviousLines.find((line) => line.key === key)) {
              combinedData.PreviousLines.push({
                key: key,
                name:
                  zoneId == '0'
                    ? 'New bookings prev. period'
                    : `${zone?.name} new bookings prev. period`,
                color: zoneId == '0' ? 'red' : getColorHash(zone!.name),
                zoneName: zone?.name,
              });
            }
          });
        }
      });
  }

  combinedData.Data = combinedData.Data.sort((a, b) => {
    return Number(a.LOS) - Number(b.LOS);
  }).map((x) => {
    var _data = {
      Current: x.Current,
      Previous: x.Previous,
    } as DataPoint;

    combinedData.CurrentLines.forEach((line) => {
      if (!!line.zoneName) {
        var previousLine = combinedData.PreviousLines.find(
          (_pLine) => _pLine.zoneName == line.zoneName
        );

        _data[line.key] = x[line.key];
        if (!!previousLine) {
          _data[previousLine!.key] = x[previousLine!.key];
        }
      }
    });

    return {
      ..._data,
      LOS: x.LOS,
    };
  });

  var los = endDate.diff(startDate, 'days') + 1;

  return (
    <Main>
      <GraphTop>
        <LeftContent>
          <Label label="Ref. period from date">
            <PTDatePicker
              dateFormat="yyyy-MM-dd"
              onChange={(date: Date) => {
                var _date = moment(date);
                var days = refEndDate.diff(refStartDate, 'days');
                debugger;
                setRefStartDate(_date);
                setRefEndDate(moment(_date).add(days, 'days'));
                setCompareDate(getSameDayLastYear(_date));
                var visualStartDate = moment(_date).add(days, 'day');
                setStartDate(visualStartDate);
                setEndDate(
                  moment(visualStartDate).add(
                    endDate.diff(startDate, 'days'),
                    'days'
                  )
                );
              }}
              selected={refStartDate.toDate()}
              calendarStartDay={5}
              selectsStart
              showMonthDropdown
              showYearDropdown
            />
          </Label>
          <Label label="Ref. period to date">
            <PTDatePicker
              dateFormat="yyyy-MM-dd"
              onChange={(date: Date) => {
                var _date = moment(date);
                setRefEndDate(moment(date));
                if (_date < refStartDate) {
                  setRefStartDate(_date);
                }
              }}
              maxDate={moment().add(-1, 'day').toDate()}
              selected={refEndDate.toDate()}
              dropdownMode="select"
              selectsStart
              showMonthDropdown
              showYearDropdown
            />
          </Label>
          <Label label="Compare from date">
            <PTDatePicker
              dateFormat="yyyy-MM-dd"
              onChange={(date: Date) => {
                setCompareDate(moment(date));
              }}
              selected={compareDate.toDate()}
              dropdownMode="select"
              selectsStart
              showMonthDropdown
              showYearDropdown
            />
          </Label>
          <Label label="Compare to date">
            <PTDatePicker
              dateFormat="yyyy-MM-dd"
              onChange={(date: Date) => {}}
              selected={moment(compareDate)
                .add(refEndDate.diff(refStartDate, 'days'), 'days')
                .toDate()}
              disabled={true}
              selectsStart
              showMonthDropdown
              showYearDropdown
            />
          </Label>
          <Label
            label="Visible period from date"
            labelStyle={{ marginLeft: '40px' }}
          >
            <PTDatePicker
              dateFormat="yyyy-MM-dd"
              onChange={(date: Date) => {
                var _date = moment(date);
                var daysBetween = endDate.diff(startDate, 'days');

                setStartDate(moment(_date));
                setEndDate(moment(_date).add(daysBetween, 'days'));
              }}
              selected={startDate.toDate()}
              dropdownMode="select"
              selectsStart
              showMonthDropdown
              showYearDropdown
              minDate={moment(refStartDate).toDate()}
            />
          </Label>
          <Label label="To date">
            <PTDatePicker
              dateFormat="yyyy-MM-dd"
              onChange={(date: Date) => {
                setEndDate(moment(date));
              }}
              selected={endDate.toDate()}
              dropdownMode="select"
              selectsStart
              showMonthDropdown
              showYearDropdown
              minDate={moment(startDate).add(1, 'days').toDate()}
            />
          </Label>
        </LeftContent>
        <RightContent>
          {!!current && (
            <Sticker
              title={'Current period'}
              diffValue={previous?.total ?? 0}
              value={current?.total ?? 0}
              showDiff={true}
            />
          )}
          {!!previous && (
            <Sticker title={'Previous period'} value={previous?.total ?? 0} />
          )}
          <ExportButton
            name={`New Bookings`}
            data={{
              data: combinedData.Data.map((f) => {
                var obj = {
                  Date: moment(startDate)
                    .add(f.LOS, 'days')
                    .format('YYYY-MM-DD'),
                  PreviousDate: moment(compareDate)
                    .add(f.LOS, 'days')
                    .format('YYYY-MM-DD'),
                  LOS: f.LOS,
                  Current: f.Current,
                  Previous: f.Previous,
                } as any;
                return obj;
              }),
              extra: {
                startDate: startDate.format('YYYY-MM-DD'),
                endDate: endDate.format('YYYY-MM-DD'),
                compareDate: compareDate.format('YYYY-MM-DD'),
                compareEndDate: moment(compareDate)
                  .add(
                    getDaysBetweenDates(startDate.toDate(), endDate.toDate()),
                    'days'
                  )
                  .format('YYYY-MM-DD'),
                total: current?.total ?? 0,
                previousTotal: previous?.total ?? 0,
                ...zoneIds.map((f) => {
                  var zone = zones.find((x) => x.id === f);
                  return zone!.name;
                }),
              },
            }}
          />
        </RightContent>
      </GraphTop>
      <div style={{ position: 'relative' }}>
        <LineChart
          xTickFormatter={(value: any) => {
            var days = Number(value);
            console.log(days);
            return moment(refStartDate)
              .startOf('days')
              .add(days, 'days')
              .format('DD/MM');
          }}
          xTicks={los}
          data={combinedData.Data}
          tooltip={{
            ...combinedData.CurrentLines.reduce((acc, f) => {
              return {
                ...acc,
                [f.key]: {
                  title:
                    f.key === 'Current'
                      ? 'Amount of new bookings'
                      : `${f.name.split('-')[0]} Amount of new bookings`,
                  color: f.color,
                  formatter: (value: any) => {
                    return `${value}`;
                  },
                },
              };
            }, {}),
            Previous: {
              title: 'New bookings prev. period',
              color: 'red',
              formatter: (value: any) => {
                return `${value}`;
              },
            },
            ...combinedData.PreviousLines.reduce((acc, f) => {
              return {
                ...acc,
                [f.key]: {
                  title:
                    f.key === 'Previous'
                      ? 'New bookings prev. period'
                      : `${f.name.split('-')[0]} New bookings prev. period`,
                  color: f.color,
                  formatter: (value: any) => {
                    return `${value}`;
                  },
                },
              };
            }, {}),
            LOS: {
              title: 'Days ahead',
              color: 'black',
              formatter: (value: any) => {
                return [
                  `${value} days`,

                  <Payload color={'black'}>
                    Date:{' '}
                    {moment(startDate).add(value, 'days').format('YYYY-MM-DD')}
                  </Payload>,
                  <Payload color={'black'}>
                    Compare date:{' '}
                    {moment(compareDate)
                      .add(value, 'days')
                      .format('YYYY-MM-DD')}
                  </Payload>,
                ];
              },
            },
          }}
          lines={[...combinedData.CurrentLines, ...combinedData.PreviousLines]}
          xDataKey="LOS"
        />
        {combinedData.Data.length === 0 && (
          <div
            style={{
              position: 'absolute',
              left: 0,
              top: 0,
              right: 0,
              bottom: 0,
              backgroundColor: '#ffffff',
              opacity: 0.35,
            }}
          >
            <Loader
              title={'Could not find data'}
              forceError={
                !!current && !!current.data && !!previous && !!previous.data
              }
            />
          </div>
        )}
      </div>
    </Main>
  );
};

export default NewBookings;
