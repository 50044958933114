import React from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../store/definitions';
import Modal from './Modal';
import useModalStack from './Modal/useModalStack';

const HiddenSaveModal: React.FC<{}> = ({}) => {
  const state = useSelector((state) => state) as ApplicationState;
  const modalStack = useModalStack();
  return (
    <Modal>
      <button
        onClick={(evt) => {
          fetch('/apdemo/scratchdata', {
            method: 'PUT',
            body: JSON.stringify({
              zoneData: state.zoneData,
              eventData: state.eventData,
              uiState: state.uiState,
            }),
            headers: {
              'Content-Type': 'application/json',
            },
          })
            .then((result) => {
              modalStack.pop();
            })
            .catch((err) => console.log(err));
        }}
      >
        Save storage
      </button>
      <button
        onClick={(evt) => {
          fetch('/apdemo/scratchdata', {
            method: 'DELETE',
          })
            .then((result) => {
              modalStack.pop();
            })
            .catch((err) => console.log(err));
        }}
      >
        Clear storage
      </button>
    </Modal>
  );
};

export default HiddenSaveModal;
