import React from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../store/definitions';
import styled from 'styled-components';
import Spinner from '../Spinner';

const LoaderHolder = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
  width: 100%;
  //aspect-ratio: 2.5;
  height: 100%;

  font-size: 30px;
  font-weight: 500;
`;

const SpinnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

interface Props {
  title?: string;
  subTitle?: string;
  forceError?: boolean;
}

const Loader: React.FC<Props> = ({
  title = 'Something went wrong',
  subTitle,
  forceError = false,
}) => {
  return (
    <LoaderHolder>
      {forceError && <>{title}</>}
      {!forceError && (
        <>
          <SpinnerWrapper>
            <span>Loading </span>
            <Spinner dotSize={5} />
          </SpinnerWrapper>
          {subTitle && (
            <>
              <br />
              <span>{subTitle}</span>
            </>
          )}
        </>
      )}
    </LoaderHolder>
  );
};

export default Loader;
