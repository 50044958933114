import { start } from 'repl';
import { UISTORE } from './constants';

export const zoneChangeActive = (zoneIds: number[]) => ({
  type: UISTORE.CHANGE_ACTIVE_ZONE,
  zoneIds,
});

export const facilityChangeActive = (
  facilityId: string,
  viewSegment: string
) => ({
  type: UISTORE.CHANGE_ACTIVE_FACILITY,
  facilityId,
  viewSegment,
});

export const facilityChangedComplete = () => ({
  type: UISTORE.CHANGE_ACTIVE_FACILITY_COMPLETE,
});

export const uiChangeDuration = (
  duration: 'day' | 'week' | 'month' | 'weeksInYear' | 'monthsInYear'
) => ({
  type: UISTORE.CHANGE_DURATION,
  duration,
});

export const uiChangeStartDate = (startDate: string) => ({
  type: UISTORE.CHANGE_START_DATE,
  startDate,
});
export const uiChangeEndDate = (endDate: string) => ({
  type: UISTORE.CHANGE_END_DATE,
  endDate,
});

export const uiDateMove = (plusMinus: number) => ({
  type: UISTORE.DATEMOVE,
  plusMinus,
});

export const uiChangeSegment = (segment: 'all' | 'driveup' | 'booking') => ({
  type: UISTORE.CHANGE_SEGMENT,
  segment,
});

export const uiChangeShowPrices = (showPrices: boolean) => ({
  type: UISTORE.SHOW_PRICES,
  showPrices,
});

export const uiChangePViewID = (id: string) => ({
  type: UISTORE.CHANGE_PVIEW_ID,
  id,
});

export const uiChangeRevView = (
  rtype: 'regular' | 'changes' | 'lastPeriod' | 'lastYear' | 'currProp'
) => ({
  type: UISTORE.CHANGE_REVVIEW,
  rtype,
});

export const setSpecialSelect = (specialSelect: string[]) => ({
  type: UISTORE.SET_SPECIAL_SELECT,
  specialSelect,
});

export const uiSetDataTS = (ts: number) => ({
  type: UISTORE.UPDATE_TS,
  ts,
});
export const uiSetCDataTS = (ts: number) => ({
  type: UISTORE.UPDATE_COMP_TS,
  ts,
});

export const uiSetAllSelected = (allSelected: boolean) => ({
  type: UISTORE.SET_ALL_SELECTED,
  allSelected,
});

export const uiResetToFetch = () => ({
  type: UISTORE.RESET_FETCH,
});
export const usSetFailedToFetch = () => ({
  type: UISTORE.FAILED_TO_FETCH,
});

export const uiSetEnableCompare = (enableCompare: boolean) => ({
  type: UISTORE.SET_ENABLE_COMPARE,
  enableCompare,
});
export const uiSetCompareDate = (startDate: string) => ({
  type: UISTORE.SET_COMPARE_DATE,
  startDate,
});

export const uiSetCompareEndDate = (endDate: string) => ({
  type: UISTORE.SET_COMPARE_END_DATE,
  endDate,
});

export const uiClearCompareDate = () => ({
  type: UISTORE.CLEAR_COMPARE_DATE,
});
