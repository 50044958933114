import { COMPARECACHEACTIONS } from './constants';
import { CompareDataSet } from './definitions';

export const pushCompareCachePending = (
  compareType: string,
  zoneIdSet: string[],
  viewStartDate: string,
  viewEndDate: string,
  srcStartDate: string,
  srcEndDate: string
) => {
  const oDesc = {
    type: COMPARECACHEACTIONS.COMPARECACHE_PUSHPENDING,
    compareType,
    zoneIdSet,
    viewStartDate,
    viewEndDate,
    srcStartDate,
    srcEndDate,
  };
  return { ...oDesc, idkey: JSON.stringify(oDesc) };
};

export const pushCompareCacheData = (
  compareType: string,
  zoneIdSet: string[],
  viewStartDate: string,
  viewEndDate: string,
  srcStartDate: string,
  srcEndDate: string,
  dataByZone: { [zoneId: string]: { revenue: number }[] }
) => ({
  type: COMPARECACHEACTIONS.COMPARECACHE_PUSHDATA,
  compareType,
  zoneIdSet,
  viewStartDate,
  viewEndDate,
  srcStartDate,
  srcEndDate,
  dataByZone,
});

export const pushCompareCacheDataFromDesc = (
  compareType: string,
  data: CompareDataSet
) => ({
  ...data,
  compareType,
  type: COMPARECACHEACTIONS.COMPARECACHE_PUSHDATA,
});
