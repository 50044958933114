import React, { FC, useState } from 'react';
import Label from 'components/input/Label';
import Select from 'components/input/Select';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import {
  uiChangeDuration,
  uiChangeEndDate,
  uiChangePViewID,
  uiChangeRevView,
  uiChangeSegment,
  uiChangeShowPrices,
  uiChangeStartDate,
  uiSetCompareDate,
  uiSetCompareEndDate,
  uiSetEnableCompare,
} from 'store/ui/action';
import {
  selectUIShowPrices,
  selectUiStateDuration,
  selectUIStateEnableCompare,
  selectUiStateEndCompareDate,
  selectUiStateEndDate,
  selectUiStatePriceTypeViewID,
  selectUIStateRevView,
  selectUiStateSegment,
  selectUiStateStartCompareDate,
  selectUiStateStartDate,
  selectUIValidPriceTypes,
} from 'store/ui/selector';
import moment, { duration } from 'moment';
import { getUser, selectUISettingZones } from 'store/uiSettings/selectors';
import { selectCurrentFacility, selectCurrentZones } from 'store/zone/selector';
import DateRangePicker from 'components/DateRangePicker';
import Button, { ButtonColors } from 'components/input/Button';
import useLocalStorage from 'provider/useLocalStorage';
import { getDaysBetweenDates, getSameDayLastYear } from 'util/date';

const segmentInputClass = 'segmentInput';
const periodInputClass = 'periodInput';
const pricesInputClass = 'pricesInput';

const fromDateClass = 'fromDate';
const toDateClass = 'toDate';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  grid-template-rows: 1fr;
  grid-column-gap: 20px;
  margin: 20px 0;
  flex: 1;

  & > * {
    min-width: 140px;
  }

  .${segmentInputClass} {
    grid-area: 1/1/2/2;
  }
  .${pricesInputClass} {
    grid-area: 1/3/2/4;
  }
  .${periodInputClass} {
    grid-area: 1/2/2/3;
  }

  .${fromDateClass} {
    grid-area: 1/5/2/6;
  }
  .${toDateClass} {
    grid-area: 1/6/2/7;
  }
`;

interface Props {
  hidePrices?: boolean;
  showRevenue?: boolean;
  kind?: string;
}
interface PriceType {
  value: string;
  name: string;
}

const GraphParams: FC<Props> = ({ hidePrices, showRevenue, kind }) => {
  const dispatch = useDispatch();

  const { read, write, getGraphViewKey } = useLocalStorage();

  const user = useSelector(getUser);
  const curDuration = useSelector(selectUiStateDuration);
  const curSegment = useSelector(selectUiStateSegment);
  const curZones = useSelector(selectCurrentZones);

  const showPrices = useSelector(selectUIShowPrices);
  const curPriceViewTypeID = useSelector(selectUiStatePriceTypeViewID);
  const startDate = useSelector(selectUiStateStartDate);
  const endDate = useSelector(selectUiStateEndDate);
  const revView = useSelector(selectUIStateRevView);
  const facility = useSelector(selectCurrentFacility);
  const zones = useSelector(selectUISettingZones);
  const enableCompare = useSelector(selectUIStateEnableCompare);
  const compareStartDate = useSelector(selectUiStateStartCompareDate);
  const compareEndDate = useSelector(selectUiStateEndCompareDate);

  const [showCompare, toggleShowCompare] = useState<boolean>(
    !!compareStartDate
  );

  var view =
    read(getGraphViewKey(user.id!)) === ''
      ? 'bars'
      : read(getGraphViewKey(user.id!))!;
  const [graphView, setGraphView] = useState<string>(view);

  const cachedPriceSeries = useSelector(selectUIValidPriceTypes);

  const supportedPrices: PriceType[] = [
    {
      value: 'hour',
      name: 'Hour',
    },
    {
      value: 'hour_avg',
      name: 'Hour average',
    },
    {
      value: 'day',
      name: 'Day',
    },
    {
      value: 'week',
      name: 'Week',
    },
  ];
  let availablePriceTypes: PriceType[] = [];
  const selectedZoneSettings = zones.find(
    (zone) => zone.zoneId === curZones[0].id
  );
  if (selectedZoneSettings !== undefined) {
    supportedPrices.forEach((priceType) => {
      const availableType = selectedZoneSettings.enabledPriceTypes.find(
        (obj) => obj === priceType.value
      );
      if (availableType !== undefined) {
        availablePriceTypes.push(priceType);
      }
    });
  }

  //Filtering prices dropdown based of Time period
  const pricesList = availablePriceTypes.filter((f) => {
    switch (curDuration) {
      case 'day':
        return f.value === 'hour';
      //case 'week':
      //  return f.value !== 'week';
      case 'month':
      case 'weeksInYear':
        return f.value !== 'hour';
      default:
        return f;
    }
  });

  const LeftContent = styled.div`
    display: flex;
    gap: 20px;

    .${periodInputClass}, .${segmentInputClass} {
      width: 190px;
    }
  `;
  const RightContent = styled.div`
    display: flex;
    gap: 40px;
  `;

  // pick a valid value from the list, otherwise default to a hour view.
  return (
    <Wrapper>
      <LeftContent>
        <Label label="Segment" className={segmentInputClass}>
          <Select
            value={curSegment}
            onChange={(evt) => {
              //if (evt.target.value === 'boorevenking') return;
              dispatch(uiChangeSegment(evt.target.value as any));
            }}
          >
            {facility.segments.length > 1 ? (
              <option value="all">All</option>
            ) : null}
            {facility.segments.indexOf('driveup') != -1 ? (
              <option value="driveup">Driveup</option>
            ) : null}
            {facility.segments.indexOf('booking') != -1 ? (
              <option value="booking">Booking</option>
            ) : null}
          </Select>
        </Label>
        <Label label={'Graph display'} className={segmentInputClass}>
          <Select
            value={graphView}
            onChange={(ev) => {
              setGraphView(ev.target.value);
              write(getGraphViewKey(user.id!), ev.target.value);
            }}
          >
            <option value="bars">Bars</option>
            <option value="line">Line</option>
          </Select>
        </Label>

        {/* <Label label="Time period" className={periodInputClass}>
          <Select
            onChange={(evt) => {
              const value = evt.target.value as string;
              dispatch(uiChangeDuration(value as any));
            }}
            value={curDuration}
          >
            <option value="day">Day</option>
            <option value="week">Week</option>
            <option value="month"> Month</option>
            <option value="weeksInYear">Year</option>
          </Select>
        </Label> */}

        {/* {!hidePrices && availablePriceTypes.length > 0 && (
          <Label label="Prices" className={pricesInputClass}>
            <Select
              onChange={(evt) => {
                dispatch(uiChangePViewID(evt.target.value as any));
              }}
              value={curPriceViewTypeID}
            >
              {cachedPriceSeries.map((cps) => (
                <option key={cps.id} value={cps.id}>
                  {cps.name} {cps.isAverage ? ' average' : ''}
                </option>
              ))}
            </Select>
          </Label>
        )} */}
      </LeftContent>
      <RightContent>
        {curSegment === 'driveup' && availablePriceTypes.length > 0 && (
          <Label label="Prices" className={pricesInputClass}>
            <input
              style={{
                marginTop: '13px',
              }}
              type="checkbox"
              disabled={curZones.length !== 1}
              checked={!!showPrices && curZones.length == 1}
              onChange={(evt) => {
                dispatch(uiChangeShowPrices(!showPrices));
              }}
            />
          </Label>
        )}
        {false && (
          <>
            <Label label="Showing From" className={fromDateClass}>
              {moment(startDate).format('yyyy-MM-DD')}
            </Label>
            <Label label="Showing To" className={toDateClass}>
              {moment(endDate).format('yyyy-MM-DD')}
            </Label>
          </>
        )}
        <DateRangePicker
          onChange={(dates) => {
            var daysBetween = Math.ceil(
              getDaysBetweenDates(
                moment(startDate).toDate(),
                moment(endDate).toDate()
              )
            );
            if (
              moment(dates.from).format('YYYY-MM-DD') !=
              moment(startDate).format('YYYY-MM-DD')
            ) {
              var newEndDate = moment(dates.from).add(daysBetween, 'days');
              if (
                moment(dates.to).format('YYYY-MM-DD') ==
                newEndDate.format('YYYY-MM-DD')
              ) {
                dates.to = newEndDate.toDate();
              }
              dispatch(
                uiChangeStartDate(moment(dates.from).format('yyyy-MM-DD'))
              );
              dispatch(
                uiSetCompareDate(
                  getSameDayLastYear(moment(dates.from)).format('yyyy-MM-DD')
                )
              );
            }
            if (
              moment(dates.to).format('YYYY-MM-DD') !=
              moment(endDate).format('YYYY-MM-DD')
            ) {
              dispatch(uiChangeEndDate(moment(dates.to).format('yyyy-MM-DD')));
            }
          }}
          startDate={moment(startDate)}
          endDate={moment(endDate)}
          startLabel="Showing from:"
          endLabel="Showing to:"
        />
        {!compareStartDate && !showCompare && (
          <Button
            color={ButtonColors.add}
            onClick={() => {
              toggleShowCompare(!showCompare);
            }}
          >
            Add Compare
          </Button>
        )}
        <input
          style={{
            marginRight: '-35px',
            alignSelf: 'flex-start',
            marginTop: '35px',
          }}
          type="checkbox"
          checked={enableCompare}
          onChange={(evt) => {
            console.log('EnableComp[are:' + evt.target.checked);
            dispatch(uiSetEnableCompare(!enableCompare));
          }}
        />
        {(!!compareStartDate || showCompare) && (
          <DateRangePicker
            onChange={(dates) => {
              if (dates.from != moment(startDate).toDate()) {
                dispatch(
                  uiSetCompareDate(moment(dates.from).format('yyyy-MM-DD'))
                );
              }
            }}
            fadeIt={!enableCompare}
            startDate={!compareStartDate ? undefined : moment(compareStartDate)}
            endDate={!compareEndDate ? undefined : moment(compareEndDate)}
            startLabel="Comparing from:"
            endLabel="Comparing to:"
            endDisabled={true}
          />
        )}
      </RightContent>
    </Wrapper>
  );
};

export default GraphParams;
