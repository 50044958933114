import React, { FC } from 'react';
import styled, { css } from 'styled-components';

const Wrapper = styled.label<{ halfwayOffStyling?: boolean }>`
  margin: 0;

  &:hover,
  &:focus,
  &:focus-within {
    border-color: ${({ theme }) => theme.palette.primary.bg};
  }

  & > input {
    position: fixed;
    top: -100px;
    left: -100px;
    opacity: 0;
  }

  & > div {
    position: relative;
    width: 48px;
    height: 24px;
    border-radius: 12px;
    background-color: ${({ theme }) => theme.palette.disabled.fg};
    transition: background-color 0.2s;
    cursor: pointer;
  }

  & > div::before {
    content: '';
    position: absolute;
    top: calc(50% - 4px);
    left: 8px;
    background-color: ${({ theme }) => theme.palette.disabled.bg};
    width: 8px;
    height: 8px;
    border-radius: 50%;
    transition: left 0.2s;
  }

  & > input:focus + div {
    box-shadow: 0 0 0 1px ${({ theme }) => theme.palette.text.bg},
      0 0 0 3px ${({ theme }) => theme.palette.primary.bg};
  }

  & > input:checked + div {
    background-color: ${({ theme }) => theme.palette.primary.bg};
  }

  & > input:checked + div:before {
    left: 32px;
    right: 8px;
  }

  ${({ halfwayOffStyling }) =>
    halfwayOffStyling &&
    css`
      & > input:checked + div::before {
        left: 20px;
        right: 20px;
      }
    `}
`;

interface Props {
  onChange?(eve: React.ChangeEvent<HTMLInputElement>): void;
  checked?: boolean;
  disabled?: boolean;
  /** style the toggle to stick halfway off. Only affects style if checked == true. */
  halfwayOffStyling?: boolean;
}

const Toggle: FC<Props> = ({
  disabled,
  onChange,
  checked,
  halfwayOffStyling,
}) => {
  return (
    <Wrapper halfwayOffStyling={halfwayOffStyling}>
      <input
        disabled={disabled}
        type="checkbox"
        onChange={onChange}
        checked={checked}
      />
      {!disabled && <div />}
    </Wrapper>
  );
};

export default Toggle;
